.informationDetail{
    width: 80%;
    // height: 800px;
    background-color: #f2f2f2;
    margin: 20px auto;
    padding-bottom: 33px;

    .informationDetail_main{
        width: 64%;
        // height: 760px;
        background-color: #fff;
        display: inline-block;
        margin-top: 20px;
        margin-left: 222px;
        border-radius: 10px;
        padding-bottom: 33px;
        .back{
            margin-top: 10px;
            margin-left: 10px;
            cursor: pointer
        }
        .informationDetail_title{
            text-align: center;
        }
        .time{
            text-align: center;
        }
    }
}
.informationMain{
    a{
        color: rgb(255, 107, 55);

    }
}
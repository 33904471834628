.TrainTextPage{
    padding: 20px;
    margin-top: 20px;
    line-height: 26px;
    overflow: hidden;
    h1{
        text-align: center;
    }
    .ti-w{
        text-indent: 2em;
    }
    .ti-4{
        text-indent: 4em;
    }
    .ti-3{
        text-indent: 3em;
    }
    .colorBlue{
        color: #409eff;
    }
    .fwb{
        font-weight: bold;
    }
    .rightSign{
        float: right;
        font-weight: bold;
        margin-top: 10px;
        margin-right: 40px;
    }
}
.Buyersorderbox{
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;
    .title{
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }
    .Buyersorder{
        
        border: 1px solid #eee;
        width: 1200px;
        height: calc(100vh - 145px);
        min-height: 740px;
        padding: 20px;
        .buyerOrderSearchContainer{
            Input,Select{
                 width: 70%;
            }
            .date{
                input{
                    width: 100%;
                }
            }
        }
    }
}
// .container-line {
//     border-top: solid 2px #ccc;
// }
// .cb-home {
//     min-width: 1200px;
//     background: #fff;
//     width: 1200px;
//     margin: 0 auto;
// }
.ant-layout {
    background: #fff;
}

.packDownload {
    // background: url('./images/bg.png');
    // background-size: 100% 100%;
    padding-top: 20px;
    padding-bottom: 150px;
    min-height: 515px;
  
    h1 {
        margin-bottom: 20px;
    }
}

.personalStore {
    min-width: 1300px;
    width: 100%;
    .personalStoreNav {
        border-bottom: solid 1px #ccc;
        height: 60px;
        line-height: 60px;
        .navContainer {
            display: flex;
            width: 1125px;
            margin: auto;
            justify-content: space-between;
            .storeName {
                font-size: 22px;
            }
            .storeOptions {
                .storeOption {
                    display: flex;
                    span {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .store_content {
        width: 1125px;
        margin: auto;
        height: auto;
        margin-top: 60px;
        // height: 290px;
        // border: solid 1px #ccc;
        // background: #fff;
        .store_info {
            width: 100%;
            padding: 0 1%;
            height: 290px;
            border: solid 1px #ccc;
            margin: auto;
            margin-top: 20px;
            dl {
                display: flex;
                padding-top: 35px;
                dt {
                    height: 220px;
                    width: 320px;
                    border: solid 1px #ccc;
                    text-align: center;
                    .storePic {
                        display: block;
                        cursor: pointer;
                        width: 270px;
                        height: 180px;
                        margin: auto;
                        margin-top: 5px;
                    }
                    p {
                        display: flex;
                        margin-top: 5px;
                        padding-left: 25px;
                        .pic {
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                }
                dd {
                    padding-left: 14px;
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    .store_discribe {
                        h4 {
                            font-size: 20px;
                            font-weight: bold;
                        }
                        p {
                            display: flex;
                            align-items: center;
                            margin-top: 15px;
                            span {
                                &:nth-child(1) {
                                    margin-right: 10px;
                                    width: 60px;
                                }
                            }
                        }
                        .conceal {
                            display: inline-block;
                            width: 300px;
                            /*定义块元素的宽度*/
                            white-space: nowrap;
                            /*内容超宽后禁止换行显示*/
                            overflow: hidden;
                            /*超出部分隐藏*/
                            text-overflow: ellipsis;
                            /*文字超出部分以省略号显示*/
                        }
                    }
                    .attestationContainer {
                        display: flex;
                        flex-direction: column;
                        .Certification_details {
                            display: flex;
                            .Certification_title {
                                margin-right: 5px;
                            }
                            .Certification_card {
                                .Certification_card_list {
                                    margin-bottom: 5px;
                                    vertical-align: top;
                                    .card_01,
                                    .card_02,
                                    .card_03 {
                                        color: #0099ff;
                                        cursor: pointer;
                                    }
                                }
                                .Certification_card_list span {
                                    width: 110px;
                                    display: inline-block;
                                    img {
                                        display: inline-block;
                                        margin-right: 5px;
                                        position: relative;
                                        top: -1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .informationContent {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            .companyInfo {
                display: flex;
                flex-direction: column;
                width: 35%;
                .title {
                    margin-left: 10px;
                     background-color: white;
                     font-size: 17px;
                     margin-bottom: 0px;
                  
                    span {
                        &:nth-child(1) {
                            color: #ff6b37;
                            font-size: 18px;
                            font-weight: bold;
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                }
                .commitmentContainer {
                    border: solid 1px #ccc;
                    min-height: 60px;
                    display: flex;
                    flex-direction: column;
                    // .title{
                    //     display: flex;
                    //     justify-content: space-between;
                    //     height: 44px;
                    //     line-height: 44px;
                    //     background: #f5f5f5;
                    //     margin-top: 15px;
                    //     padding-left: 5px;
                    //     padding-right: 10px;
                    //     color: #ff6837;
                    // }
                    .commitmentTitle {
                        margin-right: 5px;
                    }
                    .commitmentContent {
                        display: flex;
                        flex-direction: column;
                        margin-left: 10px;
                        padding-bottom: 20px;

                        span {
                            margin-top: 10px;
                            i {
                                display: inline-block;
                                font-style: normal;
                                &:first-of-type {
                                    margin-left: 10px;
                                }
                            }
                            img {
                                width: 22px;
                                height: auto;
                                margin-right: 5px;
                                margin-bottom: 5px;
                            }
                        }
                    }
                    .readMore {
                        text-align: center;
                        color: #0099ff;
                        cursor: pointer;
                    }
                }
                .contact {
                    border: solid 1px #ccc;
                    min-height: 127px;
                    .contactContent {
                        margin-left: 10px;
                        width: 76%;
                        display: flex;
                        flex-wrap: wrap;
                        p {
                            width: 50%;
                        }
                    }
                }
                .companyIntroduce {
                    border: solid 1px #ccc;
                    min-height: 493px;
                    .companyContent {
                        margin-left: 10px;
                        margin-right: 15px;
                        dl {
                            dt {
                                img {
                                    width: 100%;
                                    height: 265px;
                                }
                            }
                            dd {
                                margin-top: 20px;
                                margin-bottom: 30px;
                                text-indent: 2em;
                            }
                        }
                    }
                }
            }
            .goodsContainer {
                flex: 1;
                margin-left: 2%;
                display: flex;
                flex-direction: column;
                width: 65%;
                .goodsSearch {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .bothGoods {
                        display: inline-block;
                        width: 80px;
                        height: 35px;
                        line-height: 35px;
                        background: #ff6b37;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                    .searchContainer {
                        display: flex;
                        flex: 1;
                        input {
                            flex: 1;
                            border: solid 2px #ff6b37;
                            height: 35px;
                            line-height: 35px;
                            padding-left: 10px;
                            box-sizing: border-box;
                        }
                        span {
                            width: 80px;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            background: #ff6b37;
                            color: #fff;
                            font-size: 14px;
                            cursor: pointer;
                        }
                    }
                }
                .goodsListContainer {
                    border: solid 1px #ccc;
                    margin-top: 8px;
                    .title {
                        margin-left: 5px;
                        background-color: white;
                        font-size: 17px;
                        span {
                            &:nth-child(1) {
                                color: #ff6b37;
                                font-size: 18px;
                                font-weight: bold;
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }
                    }
                    .goodsList {
                        display: flex;
                        width: 100%;
                        padding: 0 2%;
                        flex-wrap: wrap;
                        dl {
                            border: solid 1px #ccc;
                            width: 30%;
                            padding: 1%;
                            margin-left: 10px;
                            margin-right: 10px;
                            dt {
                                width: 100%;
                                height: 140px;
                                img {
                                    display: inline-block;
                                    width: 100%;
                                    height: 140px;
                                }
                            }
                            dd {
                                margin-top: 8px;
                                .priceTitle {
                                    display: flex;
                                    justify-content: space-between;
                                    span {
                                        &:nth-child(1) {
                                            color: #ff6b37;
                                            font-size: 17px;
                                            font-weight: bold;
                                        }
                                    }
                                }
                                p {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                    .list_info_pagination {
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        line-height: 32px;
                        padding-bottom: 30px;
                        padding-top: 10px;
                        .first_button {
                            margin-right: 10px;
                            display: inline-block;
                            cursor: pointer;
                        }
                        .last_button {
                            margin-left: 10px;
                            display: inline-block;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
.goodsList-div{
    margin: 0 auto;
}

.forgetPasswordPage {
    height: 100vh;
    background: url('./images/bg.jpg') no-repeat;
    background-size: cover;
    display: flex;
    .forgetContainer {
        width: 400px;
        height: 300px;
        margin: auto;
        margin-top: 100px;
        background: #fff;
        overflow: hidden;
        border-radius: 4px;
        .forgetContainerTitle {
            background: #409eff;
            height: 44px;
            line-height: 44px;
            text-align: center;
            color: #fff;
            position: relative;
            img {
                position: absolute;
                display: inline-block;
                right: 18px;
                top: 13px;
                width: 20px;
                height: 20px;
            }
        }
        .forgetContent {
            .ant-select,
            .ant-select-selection {
                border-radius: 4px;
            }
            padding-top: 10px;
            .ant-form-item {
                margin-top: -8px;
            }
            .finishArea {
                text-align: center;
            }
            .finishButton {
                width: 120px;
                display: inline-block;
            }
            .avatar-uploader>.ant-upload {
                width: 80px;
                height: 80px;
            }
        }
    }
}
.LoginPage {
    height: 100vh;
    background: url('./images/bg.jpg') no-repeat;
    background-size: cover;
    display: flex;
    overflow: auto;
    .LoginContainer {
        width: 400px;
        height: 300px;
        margin: auto;
        // margin-top: 100px;
        background: #fff;
        overflow: hidden;
        border-radius: 4px;
        .LoginContainerTitle {
            background: #409eff;
            height: 44px;
            line-height: 44px;
            text-align: center;
            color: #fff;
            position: relative;
            img {
                position: absolute;
                display: inline-block;
                right: 18px;
                top: 13px;
                width: 20px;
                height: 20px;
            }
        }
        .login-form {
            width: 90%;
            margin: 0 auto;
            padding-top: 20px;
            .orderOptions {
                display: flex;
                justify-content: space-between;
                margin: 0 auto;
                width: 40%;
                margin-top: -25px;
                span {
                    color: #409eff;
                    cursor: pointer;
                }
            }
            .login-form-button {
                width: 40%;
                margin: 0 auto;
            }
            .ant-form-item {
                .ant-form-item-control-wrapper {
                    .ant-form-item-control {
                        .ant-form-explain {
                            padding-left: 70px;
                            padding-top: 5px;
                        }
                        .ant-form-item-children {
                            display: flex;
                            .inpuntSpan {
                                width: 90px;
                                height: 30px;
                                position: relative;
                                top: -5px;
                                text-align: right;
                                padding-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
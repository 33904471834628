.Qualityassurancecommitmentbox {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;

    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .Qualityassurancecommitment {
        border: 1px solid #eee;
        height: calc(100vh - 150px);
        padding: 20px;

        .brandlist {
            max-height: 200px;
            overflow: auto;
            width: 50%;
        }

        .brandlist::-webkit-scrollbar {
            // display: none;
        } 
        .brandlist::-webkit-scrollbar {/*滚动条整体样式*/
            width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
            height: 4px;
            scrollbar-arrow-color:red;

        }
        .brandlist::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
            scrollbar-arrow-color:red;
        }
        .brandlist::-webkit-scrollbar-track {/*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);
        }

        p {
            margin-bottom: 0em;

            a {
                color: #40a9ff;
            }

            .ant-checkbox-wrapper span {
                width: 20px !important;
                margin-right: 0 !important;
            }
        }

        .QcCenter {
            width: 50%;
            border: 1px solid grey;
            position: relative;

            .titles {
                position: absolute;
                top: -11px;
                left: 12px;
                background-color: white;
            }

            p {
                margin-bottom: 0em;

                .ant-checkbox-wrapper span {
                    width: 20px !important;
                    margin-right: 0 !important;
                }
            }
        }
    }
}
.train{
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;
    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }
    .contentPage{
        border: 1px solid #eee;
        width: 960px;
        height: calc(100vh - 145px);
        min-height: 700px;
        padding: 20px;
        overflow-y: scroll;
    }
}

.Img_up{
    overflow: hidden;
    margin-top: 20px;
    .leftImg{
        float: left;
    }
}
.mb-10{
    margin-bottom: 10px !important;
}
.img_photo{
    .img{
        width: 100px;
        height: 100px;
    }
}
.classtype{
    display: flex;
    span{
        flex: 1;
        border: 1px solid #eee;
        padding: 5px;
    }
}
.rightAdd{
    float: right;
    .a_url{
        margin: 0 8px;
        color:#1890ff;
    }
}
.ml-10{
    margin-left: 10px;
}
.redspan{
    color: red;
}
.hedui{
    text-align: center;
    color:#1890ff;
    cursor: pointer;
}
.zhe{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    .SpinPopup{
        position: absolute;
        top: 50%;
        left: 50%;
    }
}
.tup{
    width: 100%;
    text-align: center;
    .imgwh{
        width: 90%;
        height: 100%;
        
    }
}
.Button-lr{
    margin: 0 30px;
}
.table_os{
    overflow: scroll;
    height: 50vh;
}
// .ant-table-fixed-left table,.ant-table-fixed-right-right table{ width:min-content; }
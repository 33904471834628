.myShop{
    display: block;
    color: #ff6b37 !important;
    margin-left: 20px;
    float: left;
    position: relative;
    top: -1px;
}
.myShops{
    display: none;
}


.square_container {
    p {
        margin-bottom: 1em !important;
    }
    dl {
        margin-bottom: 1em;
    }
    width: 100%;
    min-width: 1300px;
    background: #fff;
    border-top: solid 2px #ccc;
    .square_content {
        width: 1125px;
        margin: auto;
        .square_header {
            display: flex;
            justify-content: space-between;
            .square_loge {
                margin-right: 60px;
                margin-top: 40px;
            }
            .square_search {
                width: 500px;
                margin-top: 30px;
                margin-left: -70px;
                .choose_search {
                    display: flex;
                    span {
                        color: #ff6b37;
                        cursor: pointer;
                        &:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                    .parts {
                        display: flex;
                        align-items: center;
                        input {
                            vertical-align: middle;
                        }
                        label {
                            margin-left: 3px;
                            vertical-align: middle;
                            color: #ff6b37;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
                .search_input {
                    width: 500px;
                    input {
                        width: 410px;
                        border: 0;
                        height: 36px;
                        box-sizing: border-box;
                        border: solid 2px #ff6b37;
                        padding-left: 10px;
                    }
                    .search_btn {
                        display: inline-block;
                        width: 90px;
                        height: 36px;
                        cursor: pointer;
                        background: #ff6b37;
                        line-height: 35px;
                        text-align: center;
                        color: #fff;
                        position: relative;
                        top: -1px;
                        left: 0;
                    }
                }
                .districtSearch .districtTitle {
                    display: inline-block;
                    margin-right: 10px;
                    color: #000;
                }
            }
            .service_phone {
                margin-top: 56px;
            }
        }
        // 品牌推荐
        .brand_list {
            height: auto;
            margin-top: 30px;
            border-top: solid 1px #eee;
            border-bottom: solid 1px #eee;
            .brand_title {
                display: flex;
                padding-top: 20px;
                span {
                    display: inline-block;
                    padding: 5px 20px;
                    flex-shrink: 0;
                    cursor: pointer;
                    &.active_01 {
                        color: #ff0000;
                    }
                    &.active {
                        color: #ff6b37;
                        background: #f8f8f8;
                    }
                }
                .brand_listName {
                    padding-left: 0px;
                }
            }
            .list_detail {
                display: flex;
                width: 100%;
                height: 90px;
                background: #f8f8f8;
                span {
                    display: inline-block;
                    padding: 0 10px;
                    cursor: pointer;
                    &.active {
                        color: #ff6b37;
                    }
                }
            }
            .vacancy {
                width: 100%;
                height: 20px;
            }
        }
        .result_list {
            padding-top: 25px;
            height: auto;
            min-height: 500px;
            .choose_result_list {
                display: flex;
                justify-content: space-between;
                .attestation {
                    input {
                        vertical-align: middle;
                    }
                    .word {
                        margin-left: 6px;
                        vertical-align: middle;
                        color: #ff0000;
                    }
                }
                .choose_rule {
                    .capacity,
                    .popularity,
                    .lively {
                        cursor: pointer;
                        &.active {
                            color: #f00;
                        }
                    }
                    span {
                        width: 35px;
                        display: inline-block;
                        text-align: center;
                        height: 20px;
                    }
                }
            }
            .result_list_content {
                margin-top: 10px;
                .list_info {
                    dl {
                        display: flex;
                        border-bottom: solid 2px #eee;
                        padding-bottom: 10px;
                        dt {
                            img {
                                display: block;
                                margin-right: 20px;
                                width: 200px;
                                height: 157px;
                            }
                        }
                        dd {
                            flex: 1;
                            display: flex;
                            justify-content: space-between;
                            .storeInfo {
                                .storeName {
                                    display: flex;
                                    justify-content: space-between;
                                    height: 30px;
                                    line-height: 30px;
                                    a {
                                        font-size: 15px;
                                        color: #6495ed;
                                        text-decoration: underline;
                                    }
                                }
                                p {
                                    color: #ccc;
                                }
                                .manage,
                                .intro {
                                    display: flex;
                                    align-items: center;
                                }
                                p>span {
                                    display: inline-block;
                                    color: #666666;
                                    text-align: justify;
                                    &:nth-child(1) {
                                        width: 60px;
                                    }
                                    &:nth-child(2) {
                                        margin-left: 25px;
                                    }
                                }
                                .conceal {
                                    margin-left: 5px;
                                    display: inline-block;
                                    width: 300px;
                                    /*定义块元素的宽度*/
                                    white-space: nowrap;
                                    /*内容超宽后禁止换行显示*/
                                    overflow: hidden;
                                    /*超出部分隐藏*/
                                    text-overflow: ellipsis;
                                    /*文字超出部分以省略号显示*/
                                }
                            }
                            .storeCommitment {
                                width: 18%;
                                display: flex;
                                flex-direction: column;
                                span {
                                    margin-bottom: 5px;
                                    img {
                                        width: 22px;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
                .list_info_pagination {
                    display: flex;
                    width: 1125px;
                    text-align: center;
                    justify-content: center;
                    line-height: 32px;
                    padding-bottom: 30px;
                    padding-top: 10px;
                    .first_button {
                        margin-right: 10px;
                        display: inline-block;
                        cursor: pointer;
                    }
                    .last_button {
                        margin-left: 10px;
                        display: inline-block;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.im_span{
    display: inline-block;
    width: 97px;
    position: relative;
    margin-bottom: 5px;
    font-size: 15px;
    .im_portrait{
       position: absolute;
       bottom: 0;
        display: inline-block;
        width: 22px;
        height: 26px;
        
        background-image: url(./images/default-icon.png);
        background-size: 100% 100%;
    }
    .im_zi{
        padding-left: 27px;
        cursor: pointer;
    }
}
.service{
    position: absolute;
    top: 25px;
    left: 27px;
    width: 110%;
    padding: 10px;
    text-align: center;
    background: white;
    box-shadow:4px 4px 5px 3px #999;
    border-radius: 4px;
}
.loginFunctions{
    margin-top: 50px !important;
    .spanis{
        margin: 0 !important;
    }
}
.warpper-epc{
    width: 1200px;
    height:  calc(100vh - 70px);
    margin: 0 auto;
    
}
.wire{
    width: 100%;
    border-top: 2px solid #ccc;
    
}
.spanmy{
    margin-right: 0 !important;
}
.personalStoreNavs {
    border-bottom: solid 1px #ccc;
    right: 0;
    left: 0;
    min-width: 1200px;
    background: #fff;
    border-top:solid 2px #ccc;
    .shopHomeSearch {
        position: relative;
        width: 100%;
        height: 60px;
        margin-top: 30px;
        margin-bottom: 20px;
        img {
        position: absolute;
        }
        .search_input {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 500px;
            transform: translate(-50%,-50%);
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
        input {
            width: 410px;
            border: 0;
            height: 36px;
            box-sizing: border-box;
            border: solid 2px #ff6b37;
            padding-left: 10px;
        }
        .search_btn {
            display: inline-block;
            width: 90px;
            height: 36px;
            cursor: pointer;
            background: #ff6b37;
            line-height: 35px;
            text-align: center;
            color: #fff;
            position: relative;
            top: -1px;
            left: 0;
        }
        }
    }
    .navContainer {
        width: 1200px;
        margin: 0 auto;
        .classify-titile {
            width: 200px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #ff6b37;
            color: #fff;
            margin-right: 40px;
        }
        .storeName {
            font-size: 22px;
          
        }
        .storeOptions {
            .storeOption {
                display: flex;
                line-height: 40px;
                a {
                    color: black;
                    margin-left: 45px;
                    width: 70px;
                    height: 40px;
                    text-align: center;
                }
            }
        }
    }
}
.active {
    color: #ff6b37 !important;
    border-bottom:2px solid  #ff6b37  !important;

}
.active-2 {
    color: #ff6b37 !important;
}

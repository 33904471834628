.enterprise_div{
    margin: 10px 0;
}
.Essentialinfobox {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;

    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .Essentialinfo {
        border: 1px solid #eee;
        width: 980px !important;
        height: 808px !important;
        padding: 20px;
        .photos_ke{
            display: inline-block;
            width:80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            .photos_img{
                position: absolute;
                top: 0;
                left: 0;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                
           }
           .ant-upload_int{
            position: relative;
            top: -15px;
            left: -12px;
        }
        }
        .storeImgUrl{
            .img_li{
                float: left;
                width: 80px;
                height: 80px;
                margin-right: 10px;
                position: relative;
                .store_img{
                    width: 80px;
                    height: 80px;
                }
                .myspan{
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    background-image: url(./images/quxiao.png);
                    background-size: 100% 100%;
                }
            }
            
        }
        .brandUrl_kuang{
            width: 475px;
            height: 83px;
            overflow: auto;
            .brandUrl_li{
                width: calc(100% / 4) !important;
                margin-bottom: 20px;
                text-align: center;
                position: relative;
                .spann{
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    background-image: url(./images/quxiao.png);
                    background-size: 100% 100%;

                }
            }
        }
        .brandUrl_kuang::-webkit-scrollbar{
            display: none;
        }
    }

    .Submit {
    display: block;
        height: 40px;
        width: 150px;
        border: 1px solid #ccc;
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        margin-top: 15px;
        // margin-left: 50px;
    }
}
.shopPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;

    .main {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 130px;
        background: #fff;
        border-radius: 6px;

        .title {
            width: 100%;
            background: #FF6B37;
            text-align: center;
            color: #fff;
            height: 30px;
            line-height: 30px;
            span:nth-child(2) {
                position: absolute;
                top: 0px;
                right: 30px;
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 27px;
            }
        }

        .shopCont {
            padding: 15px;
            text-align: center;
        }

        .btnBox {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 40px;

            button {
                display: inline-block;
                width: 100px;
                line-height: 30px;
                background: #FF6B37;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                border: none;
                margin: 0 auto;
            }
        }
    }
}
.shopPopup_brand{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
    .shopPopup_con{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 600px;
        height: 520px;
        background: #fff;
        border-radius: 6px;
        .shopPopup_title{
            height: 10%;
            background: #0099ff;
            border-bottom: 1px solid #000;
            .shop_span{
                color: #fff;
                display: block;
                text-align: center;
                line-height: 50px;
            }
        }
        .shopPopup_content{
            height: 90%;
            .shopPopup_left{
                float: left;
                width: 20%;
                height: 100%;
                overflow: scroll;
                
                // border-right: 1px solid #000;
                .left_li{
                    padding-left: 5px;
                    cursor: pointer;
                    
                }
            }
            .shopPopup_left::-webkit-scrollbar{
                display: none;
            }
            .shopPopup_pinyin{
                float: left;
                width: 6%;
                height: 100%;
                border-right: 1px solid #000;
                cursor: pointer;
                .pinyin_li{
                    text-align: center;
                    font-size: 12px;
                    &.cur{
                        color: #409eff;
                    }
                }
            }
            .shopPopup_rigth{
                float: left;
                width: 74%;
                height:100%;
                .shopPopup_top{
                    height: 90%;
                    // border-bottom: 1px solid #000;
                    overflow: scroll;
                    
                    .rigth_li{
                        float: left;
                        padding-left: 10px;
                        width: 80px;
                        height: 80px;
                        margin: 10px 10px;
                        position: relative;
                      
                        .rigth_img{
                            width: 60px;
                            height: 60px;
                        }
                        .myspann{
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            background-image: url(./images/quxiao.png);
                            background-size: 100% 100%;
                        }
                        
                    }
                   
                }
                .shopPopup_top::-webkit-scrollbar{
                    display: none;
                }
            }
            .shopPopup_bottom{
                
                .OK{
                    display: block;
                margin-left: 47%;
                margin-top: 10px;
                width: 100px;
                float: left;
                }
                .NO{
                    float: left;
                    display: block;
                    margin-left: 20px;
                    margin-top: 10px;
                    width: 100px;
                    
                }
            }
        }
    }
}

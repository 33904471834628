.FreeopenBox{
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;
    .title{
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }
    .Freeopen{
        border: 1px solid;
        height: calc(100vh - 150px);
        padding: 20px;
    }
}
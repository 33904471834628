.Broadside{
    width: 180px;
    border: solid 1px #ccc;
    height: 756px;
    border-radius: 3px;
    margin-top: 0 !important;
    .title {
        // display: flex;
        // justify-content: center;
        // border-bottom: solid 1px #ccc;
        text-align: center;
        color: #111;
        span {
            height: 55px;
            line-height: 55px;
        }
    }
    .content-p {
        display: flex;
        margin-bottom: 0px;
        border-bottom: 1px solid #eee;
        display: block;
        text-align: center;
        height: 44px;
        line-height: 44px;
        cursor: pointer;
        &.cur{
            background: #ecf5ff;
            color: #368DF4;
        }
        span {
            width: 100%;
            text-align: center;
            height: 55px;
            line-height:44px;
            color: #111;
        }
    }
}
:global {
    .ant-menu-horizontal {
        border: none!important;
    }
    .ant-checkbox-wrapper {
        margin-left: 30px!important;
        margin-top: 20px!important;
        span {
            width: 90px!important;
            margin-right: 0 !important;
        }
        .ant-checkbox {
            width: 15px!important;
            height: 15px!important;
            input {
                width: auto !important;
            }
            .ant-checkbox-inner {
                width: 15px!important;
                height: 15px!important;
            }
        }
    }
    .ant-select {
        .ant-select-selection {
            border-radius: 0px;
        }
        .ant-select-selection__clear {
            background: none;
            width: 5px!important;
            right: 11px;
        }
        .ant-select-selection-selected-value {
            padding-right: 0px!important;
        }
    }
    .BusinessQueryData {
        .ant-calendar-picker {
            min-width: 170px !important;
            .ant-calendar-date-panel {
                position: relative;
                top: 5px !important;
            }
        }
    }
    .userType {
        margin-top: 8px;
        i {
            margin-left: 55px !important;
        }
    }
    .isLoginModel {
        .ant-modal {
            width: 400px!important;
            .ant-modal-content {
                .ant-modal-close {
                    .ant-modal-close-x {
                        display: inline-block;
                        margin-top: -5px;
                        i {
                            color: #fff;
                        }
                    }
                }
                .ant-modal-header {
                    background: #0099ff !important;
                    .ant-modal-title {
                        margin-top: -5px;
                        color: #fff;
                        text-align: center!important;
                    }
                }
                .ant-modal-body {
                    text-align: center;
                    p {
                        margin-top: 10px;
                        b {
                            color: #f00;
                            font-weight: normal;
                        }
                        span {
                            font-size: 15px;
                            display: inline-block;
                            width: 80px;
                            height: 36px;
                            line-height: 36px;
                            border-radius: 3px;
                            background: #0099ff;
                            color: #fff;
                            cursor: pointer;
                            &:nth-child(1) {
                                margin-right: 40px;
                            }
                        }
                    }
                    .loginFunction {
                        margin-top: 50px;
                    }
                }
            }
        }
    }
    .ant-layout {
        background: #fff;
    }
    .packTable {
        min-width: 1200px;
        width: 1200px;
        height: 176px;
        margin: auto;
        margin-top: 50px;
        .l-table-row-active {
            background-color: transparent !important;
        }
        .ant-table-tbody>.l-table-row-active:hover>td {
            background-color: transparent !important;
        }
        a,
        a:visited {
            color: #1890ff;
        }
    }
    .accessoriesTable {
        .l-table-row-show {
            background-color: #95c5ed !important;
        }
    }
    .ant-table {
        table-layout: fixed;
        height: auto;
        // min-height: 250px;
        .ant-checkbox-wrapper {
            margin-left: 0px!important;
            margin-top: 0px!important;
            span {
                width: 90px!important;
                margin-right: 0 !important;
            }
            .ant-checkbox {
                width: 15px!important;
                height: 15px!important;
                input {
                    width: auto !important;
                }
                .ant-checkbox-inner {
                    width: 15px!important;
                    height: 15px!important;
                }
            }
        }
        .ant-table-content {
           
            .ant-table-placeholder {
                height: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .ant-table-thead>tr {
            width: 100% !important;
        }
        .ant-table-thead>tr>th {
            background: #f2f2f2 !important;
            padding: 10px 12px;
            .ant-table-header-column {
                display: initial!important;
                text-align: center;
            }
        }
        .ant-table-tbody>tr>td {
            padding: 10px 12px;
            word-break: break-word;
            flex-shrink: 0;
            overflow: hidden;
            box-sizing: border-box;
            text-align: center;
            .quantityContainer {
                display: flex;
                justify-content: center;
                .enquiryQuantity {
                    width: 60px;
                    margin-left: 5px;
                    margin-right: 5px;
                    background: #fff;
                    color: #000;
                    text-align: center;
                    border: solid 1px #ccc;
                }
                span {
                    width: 23px;
                    height: 23px;
                    background: #26acf5;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
            .resultColumns {
                width: inherit;
                overflow: hidden;
            }
            .storer {
                color: #0099ff;
                cursor: pointer;
            }
            .omit {
                word-break: keep-all;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .enquiryModal {
        .ant-modal-content {
            .ant-modal-body {
                input {
                    width: 198px;
                    height: 25px;
                    margin-top: 5px;
                    padding-left: 5px;
                }
            }
        }
    }
    .ant-menu-item {
        a {
            font-size: 17px!important;
        }
    }
    .ant-modal-header {
        background: #f2f2f2 !important;
        height: 44px;
        .ant-modal-title {
            font-size: 15px;
        }
    }
    .ant-modal-body {
        .adjust {
            display: flex;
            align-items: center;
            input {
                border: solid 1px #ccc;
            }
        }
        .conceal {
            display: inline-block;
            width: 200px;
            /*定义块元素的宽度*/
            white-space: nowrap;
            /*内容超宽后禁止换行显示*/
            overflow: hidden;
            /*超出部分隐藏*/
            text-overflow: ellipsis;
            /*文字超出部分以省略号显示*/
        }
        div {
            line-height: 25px;
        }
        .adjust>span:nth-child(1) {
            width: 80px;
            text-align: right;
            display: inline-block;
            margin-right: 10px;
            color: #9f9c9c;
        }
        .consult {
            width: 100%;
            background: #f2f2f2 !important;
            height: 35px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: inline-block;
                margin-right: 5px;
            }
            .interview {
                color: #000;
                cursor: pointer;
                text-decoration: none;
                position: relative;
            }
        }
        .enquiryBtn {
            margin: 0 auto;
            margin-top: 15px;
            width: 100%;
            height: 38px;
            border-radius: 5px;
            cursor: pointer;
            background-color: #0099ff;
            color: #fff;
            line-height: 38px;
            text-align: center;
        }
    }
}

//  ::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
// }
// .-o-scrollbar {
//     -moz-appearance: none !important;
//     background: rgba(0, 255, 0, 0) !important;
// }
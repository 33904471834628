.scantopay{
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;
    .title{
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }
    .payscan{
        border: 1px solid #eee;
        width: 960px;
        height: calc(100vh - 145px);
        min-height: 700px;
        padding: 20px;
    }
    .title_box{
        display: flex;
        width: 100%;
        .file{
            flex: 1;
        }
    }
    .list_info_pagination{
        width: 500px !important;
    }
}
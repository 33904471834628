.Essentialinfobox {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;

    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .Essentialinfo {
        border: 1px solid;
        height: calc(100vh - 150px);
        padding: 20px;
        
        .photos_ke{
            display: inline-block;
            width:80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            .photos_img{
                position: absolute;
                top: 0;
                left: 0;
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }
            .ant-upload_int{
                position: relative;
                top: 0;
                left: 0;
            }
        }
        
    }

    .Submits {
        height: 40px;
        width: 150px;
        border: 1px solid #ccc;
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        margin-top: 40px;
        margin-left: 76px;
    }
}
.shopPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;

    .main {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 130px;
        background: #fff;
        border-radius: 6px;

        .title {
            width: 100%;
            background: #FF6B37;
            text-align: center;
            color: #fff;
            height: 30px;
            line-height: 30px;
            span:nth-child(2) {
                position: absolute;
                top: 0px;
                right: 30px;
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 27px;
            }
        }

        .shopCont {
            padding: 15px;
            text-align: center;
        }

        .btnBox {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 40px;

            button {
                display: inline-block;
                width: 100px;
                line-height: 30px;
                background: #FF6B37;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                border: none;
                margin: 0 auto;
            }
        }
    }
}
// .ant-upload{
//     border-radius: 50% !important;
// }

.MymoneyBox{
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;
    .title{
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }
    .Mymoney{
        border: 1px solid #eee;
        width: 960px;
    height: calc(100vh - 145px);
    min-height: 790px;
        padding: 20px;
        p {
            margin-top: 0;
            margin-bottom: 1em;
        }
        div{
            .balance{
                font-weight: 700;
                font-style: normal;
                font-size: 36px;
                margin-bottom: 0.01em;
                .small{
                    font-size: 14px;
                    font-style: inherit;
                    font-weight: 500;
                }
            }
            .rechargeamount{
                border-width: 0px;
                width: 151px;
                height: 38px;
                border: 1px solid black;
                float: left;
                margin-right: 51px;
                margin-top: 10px;
                text-align: center;
                line-height: 38px;
            }
        }
    }
}
.gray{
    background-color:grey!important;
}
.accountNumberPage {
    .accountNumberContainer {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .accountNumberCatalog {
            width: 15%;
            border: solid 1px #ccc;
            height: 756px;
            border-radius: 3px;
            .title {
                display: flex;
                justify-content: center;
                border-bottom: solid 1px #ccc;
                span {
                    height: 55px;
                    line-height: 55px;
                }
            }
            p {
                display: flex;
                span {
                    width: 100%;
                    text-align: center;
                    height: 55px;
                    line-height: 55px;
                    background: #ecf5ff;
                    color: #368DF4;
                }
            }
        }
        .accountNumberContent {
            flex: 1;
            height: 756px;
            display: flex;
            flex-direction: column;
            .title {
                display: flex;
                border-bottom: solid 1px #ccc;
                height: 41px;
                line-height: 41px;
                margin-left: 15px;
                text-align: center;
                span {
                    width: 103px;
                    height: 41px;
                    border: solid 1px #ccc;
                    border-radius: 3px;
                }
            }
            .personalInfo {
                border: solid 1px #ccc;
                border-left: none;
                padding-left: 100px;
                padding-bottom: 20px;
                padding-top: 5px;
                p {
                    margin-bottom: 0px;
                    margin-top: 10px;
                    display: flex;
                    span {
                        &:nth-child(1) {
                            width: 84px;
                            text-align: right;
                        }
                        &:nth-child(2) {
                            margin-left: 15px;
                        }
                    }
                }
            }
            .upgradeCompany {
                border-right: solid 1px #ccc;
                border-bottom: solid 1px #ccc;
                flex: 1;
                padding-left: 100px;
                padding-top: 20px;
                .remarks {
                    color: #f00;
                }
                .upgradeCompanyContent {
                    .ant-form-item {
                        width: 300px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
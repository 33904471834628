.invoiceContent{
        p {
        display: flex;
        span {
            width: 15%;
            height: 40px;
            line-height: 40px;
        }
        input {
            flex: 1;
            border: solid 1px #ccc;
            height: 40px;
            line-height: 40px;
            margin-bottom: 20px;
            border-radius: 5px;
        }
    }
}
.registerButton{
    width: 300px;
   margin: 0 auto !important;
   display: block;
}
.getBack{
    width: 30px;
    height: 30px;
    background-image: url(./images/cut.png);
    background-size: 100% 100%;
    display: inline-block;
}

.contactUsPage {
    .contactContainer {
        width: 1125px;
        margin: auto;
        border: solid 1px #ccc;
        padding: 2%;
        display: flex;
        margin-top: 60px;

        .storePic {
            width: 300px;
            height: 300px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .contactMode {
            flex: 1;
            padding-right: 15%;
            margin-left: 80px;

            .contactPhone {
                p {
                    display: flex;

                    span {
                        color: #ff6b37;

                        &:nth-child(1) {
                            margin-right: 8px;
                            width: 3px;
                            height: 20px;
                            background: #ff6b37;
                        }

                        &:nth-child(2) {
                            font-weight: bold;
                        }
                    }
                }
            }

            .contactBusiness {
                margin-top: 40px;

                p {
                    display: flex;

                    span {
                        color: #ff6b37;

                        &:nth-child(1) {
                            margin-right: 8px;
                            width: 3px;
                            height: 20px;
                            background: #ff6b37;
                        }

                        &:nth-child(2) {
                            font-weight: bold;
                        }
                    }
                }

                ul {
                    li {
                        display: flex;
                        // justify-content: space-between;
                        margin-top: 20px;

                        span {
                            width: 25%;
                            text-align: center;

                            img {
                                position: relative;
                                top: -1px;
                                right: 4px;
                            }
                        }

                        .head {
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            border-radius: 100%;
                            background: #32cc78;
                            line-height: 30px;
                            text-align: center;
                            color: #fff;
                        }

                        .seeCode {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.botton {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
}
.Ordersellerbox{
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;
    .title{
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }
    .Orderseller{
        border: 1px solid #eee;
        height: calc(100vh - 150px);
        padding: 20px;
        .managementtitle .row-p .col-1 {
            margin-top: 0px;
        }
        .searchButton{
            bottom: 27px;
            right: 10px;
        }
    }
}
.Qualityassurancecommitmentbox {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;

    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .Qualityassurancecommitment {
        border: 1px solid #eee;
        height: calc(100vh - 150px);
        padding: 20px;
    }

    .cb-home {
        width: 100%
    }

    .downloadWrap {
        width: 100%;

        h1 {
            font-size: 18px;
            padding: 0 0;
            margin: 0 0;
        }
    }

    .downloadContainer {
        display: flex;
        margin: auto;
        justify-content: space-around;
        width: 100%;

        .contactNumber {
            // padding-left: 55px;

            p {
                font-size: 18px;
                margin-bottom: 0px;

                span {
                    display: inline-block;

                    &:nth-child(2) {
                        margin-left: 30px;
                    }
                }
            }
        }

        h1 {
            text-align: center;
            padding: 0;
        }

        .download-block {
            width: 25%;

            .officialAccounts {
                width: 190px;
                height: 180px;
                position: relative;
                top: -10px;
            }

            dl {
                text-align: center;

                dd {
                    width: 180px;
                    height: 180px;
                    text-align: center;
                }

                dt {
                    span {
                        // margin-top: 20px;
                        display: block;
                        font-size: 12px;

                        &:nth-child(2) {
                            a {
                                display: block;
                                color: #53c7f8;
                                margin-top: 15px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            img {
                width: 180px;
                height: 154px;
            }
        }
    }

}
.Model {
    .ant-modal {
        .ant-modal-content {
            .ant-modal-close {
                .ant-modal-close-x {
                    color: #fff;
                    line-height: 44px;
                }
            }
            .ant-modal-body {
                padding: 20px;
                padding-bottom: 5px;
                .increasePlaceContainer {
                    .increasePlaceContent {
                        .areaContainer {
                            margin-bottom: 10px;
                        }
                        p,
                        .areaContainer {
                            display: flex;
                            .detailPlace {
                                width: 70px;
                            }
                            span {
                                display: inline-block;
                                text-align: right;
                                width: 70px;
                            }
                            input {
                                margin-left: 10px;
                                border: solid 1px #ccc;
                                padding-left: 10px;
                                flex: 1;
                            }
                            textarea {
                                margin-left: 10px;
                                padding-left: 10px;
                                border: solid 1px #ccc;
                                width: 231px;
                            }
                        }
                        .defaultPlaceContainer {
                            padding-left: 60px;
                            input {
                                flex: none;
                                width: 15px;
                                height: 15px;
                                position: relative;
                                top: 6px;
                                right: 5px;
                            }
                            label {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .ant-modal-footer {
                height: 60px;
                div {
                    text-align: center;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 15px;
                    button {
                        width: 120px;
                        height: 35px;
                        &:nth-child(2) {
                            background: #FF6B37;
                            border: solid 1px #FF6B37;
                        }
                        &:nth-child(1) {
                            &:hover {
                                border: solid 1px #ccc;
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        .ant-modal-header {
            background: #FF6B37!important;
        }
        .ant-modal-title {
            // background: #000;
            text-align: center;
            color: #fff;
            line-height: 13px;
        }
    }
}

.placeTubeModel {
    .ant-modal {
        .ant-modal-content {
            // height: 200px;
            .ant-modal-close {
                .ant-modal-close-x {
                    color: #fff;
                    line-height: 44px;
                }
            }
            .ant-modal-body {
                padding: 0px;
            }
            .ant-modal-header {
                background: #FF6B37!important;
            }
            .ant-modal-title {
                // background: #000;
                text-align: center;
                color: #fff;
                line-height: 13px;
            }
        }
    }
    .tubePlaceContainer {
        .placeTable {
            height: auto !important;
            .operation {
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                span {
                    color: #0099FF;
                    cursor: pointer;
                }
            }
        }
        .increasePlace {
            right: 0;
            display: flex;
            height: 60px;
            align-items: center;
            justify-content: flex-end;
            span {
                margin-right: 40px;
                width: 116px;
                height: 38px;
                line-height: 38px;
                background: #ff6837;
                color: #fff;
                text-align: center;
                cursor: pointer;
            }
        }
    }
    .tubePlacetooltip{
        height: 150px;
       .tooltipP{
           display: block;
           width: 300px;
           margin: 0 auto;
           text-align: center;
           line-height: 30px;
        //    margin-top: 40px;
       }
       .tooltipSpan{
            display: block;
           margin: 10px auto;
           width: 80px;
           border-radius: 5px;
           padding: 5pxpx;
           margin-top: 40px;
           text-align: center;
           cursor: pointer;
           background: #ff6837;
           color: white;
           box-shadow: red  1px 1px 1px ;
           border: 1px solid #000;
       }
    }
}
.ant-table-row-cell-break-word{
    width: 100px !important;
}

.placeOrderPage {
    .placeOrderContainer {
        width: 1200px;
        margin: auto;
        .navigation {
            height: 40px;
            line-height: 40px;
            border-bottom: solid 1px #ccc;
            display: flex;
            span {
                height: 40px;
                color: #0099FF;
                border-bottom: solid 1px #0099FF;
                &:nth-child(3) {
                    color: #000;
                }
            }
        }
        .placeOrderContent {
            .title {
                display: flex;
                justify-content: space-between;
                height: 44px;
                line-height: 44px;
                background: #f5f5f5;
                margin-top: 15px;
                padding-left: 30px;
                padding-right: 10px;
            }
            .goodsPlaceContent {
                display: flex;
                flex-wrap: wrap;
                .goodsPlace {
                    background: #f5f5f5;
                    border-radius: 8px;
                    width: 23%;
                    margin-right: 8px;
                    height: 130px;
                    margin-top: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 10px;
                    p {
                        margin-bottom: 0px;
                        &:nth-child(3) {
                            margin-top: 15px;
                        }
                    }
                    .isDefault {
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .defaullt {
                    background: #ff6837;
                    p {
                        color: #fff;
                    }
                }
            }
            .placeAdministration {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                span {
                    width: 116px;
                    height: 38px;
                    line-height: 38px;
                    background: #ff6837;
                    color: #fff;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .invoiceContainer {
            .invoiceInfo {
                border: solid 1px #ccc;
                padding-left: 2%;
                padding-right: 2%;
                margin-top: 10px;
                .invoiceTypeContent {
                    display: flex;
                    margin-top: 10px;
                    height: 40px;
                    line-height: 40px;
                    span {
                        &:nth-child(1) {
                            width: 15%;
                        }
                    }
                    .invoiceType {
                        flex: 1;
                        display: flex;
                        p {
                            margin-right: 20px;
                            input {
                                margin-right: 8px;
                            }
                        }
                    }
                }
                // .invoiceContent {
                //     p {
                //         display: flex;
                //         span {
                //             width: 15%;
                //             height: 40px;
                //             line-height: 40px;
                //         }
                //         input {
                //             flex: 1;
                //             border: solid 1px #ccc;
                //             height: 40px;
                //             line-height: 40px;
                //             padding-left: 15px;
                //             color: #ccc;
                //             border-radius: 5px;
                //             margin-top: 10px;
                //             margin-bottom: 10px;
                //         }
                //     }
                   
                // }
                .no-invoice{
                    border-top: 1px solid #ccc;
                    h4{
                        // padding: 10px 0;
                        line-height: 35px;
                    }
                }
            }
        }
        .logisticszContainer {
            .logisticszContent {
                margin-top: 5px;
                border: solid 1px #ccc;
                padding-left: 2%;
                padding-right: 2%;
                height: 100px;
                .logisticszTypeContent {
                    display: flex;
                    height: 40px;
                    line-height: 40px;
                    margin-top: 10px;
                    span {
                        &:nth-child(1) {
                            width: 15%;
                        }
                        &.cur{
                            background: #ff6837;
                            color: #fff;
                        }
                    }
                    .logisticszType {
                        flex: 1;
                        display: flex;
                        span {
                            width: 99px;
                            height: 29px;
                            line-height: 29px;
                            text-align: center;
                            margin-right: 10px;
                            border: solid 1px #ccc;
                            border-radius: 3px;
                        }
                    }
                }
                p {
                    display: flex;
                    height: 40px;
                    line-height: 40px;
                    span {
                        width: 15%;
                    }
                    input {
                        flex: 1;
                        border: solid 1px #ccc;
                        padding-left: 15px;
                        color: #000;
                        border-radius: 5px;
                    }
                }
            }
        }
        .shoppingListContainer {
            .shoppingListContent {
                margin-top: 10px;
                table {
                    width: 100%;
                    border: none;
                    border-top: solid 1px#ccc;
                    border-left: solid 1px#ccc;
                    text-align: center;
                    tr {
                        th {
                            flex: 1;
                            border-bottom: solid 1px#ccc;
                            border-right: solid 1px#ccc;
                            height: 43px;
                            line-height: 43px;
                            background: #f2f2f2;
                            font-weight: normal;
                        }
                    }
                    tr {
                        display: flex;
                        td {
                            flex: 1;
                            border-bottom: solid 1px#ccc;
                            border-right: solid 1px#ccc;
                            height: 43px;
                            line-height: 43px;
                        }
                    }
                }
                .totalContainer {
                    display: flex;
                    justify-content: flex-end;
                  
                   
                    p {
                        margin-bottom: 0px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        span {
                            &:nth-child(2) {
                                width: 82px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
        .copeWithTotal {
            p {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                margin-bottom: 200px;
                .submissionOrder {
                    width: 146px;
                    height: 36px;
                    line-height: 36px;
                    color: #fff;
                    background: #FF6B37;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
}
.goLogin-p{
    font-weight: bold;
    font-size: 20px;
}
.goLogin{
    margin-left: 41px;
}
.goodsPlace defaullt{
    overflow: hidden;
    
}
.Address{
    height: 40px ;
    overflow: hidden;
}
.totalContent{
    
    height: 91px;
    margin-top: 10px;
    margin-left: 80%;
    .Content-p{
        margin-top: 10px;
        margin-bottom: 1em;
    }
}
.buyerOrderList{
    margin-top: 4px;
}
.copeWithTotal-right{
    margin-left: 80%;
}
.span-p{
    text-align: center;
}
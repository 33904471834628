a {
    text-decoration: none;
}

.accessories_container {
    width: 100%;
    min-width: 1300px;
    background: #fff;
    border-top: solid 2px #ccc;
    .accessories {
        width: 1500px;
        margin: auto;
        .parts_banner {
            width: 100%;
            img {
                display: block;
                width: 100%;
            }
        }
        .search_accessories {
            margin-top: 10px;
            border: solid 1px #ccc;
            display: flex;
            // height: 100px;
            height: 160px;
            align-items: center;
            width: 100%;
            .search_content {
                display: flex;
                flex-wrap: wrap;
                span {
                    width: 70px;
                    text-align: right;
                    display: inline-block;
                    margin-right: 8px;
                }
                input {
                    width: 256px;
                    height: 32px;
                    border: 0;
                    outline: none;
                    border: solid 1px #ccc;
                    margin-top: 10px;
                    padding-left: 8px;
                }
                .componentCode {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 10px;
                    textarea {
                        box-sizing: border-box;
                        padding-left: 5px;
                        border: solid 1px #ccc;
                        font-size: 18px;
                    }
                }
                .middleFilter {
                    width: 700px;
                    display: flex;
                    flex-wrap: wrap;
                }
                .endFilter {
                    display: flex;
                }
            }
            .functionButton {
                display: flex;
                align-items: center;
                p {
                    display: flex;
                    justify-content: flex-end;
                }
                .search_btn {
                    margin-top: 20px;
                    width: 90px;
                    background: #0099ff;
                    display: block;
                    height: 50px;
                    border-radius: 3px;
                    color: #fff;
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    cursor: pointer;
                    margin-right: 20px;
                    border: none;
                }
                .templateFunction {
                    display: flex;
                    margin-right: 20px;
                    flex-direction: column;
                    //justify-content: flex-end;
                    margin-top: 20px;
                    .tipTitle {
                        font-style: normal;
                        color: #f00;
                        text-align: center;
                    }
                    .downloadTemplate,
                    .toLead {
                        width: 130px;
                        background: #0099ff;
                        display: inline-block;
                        height: 35px;
                        border-radius: 3px;
                        color: #fff;
                        line-height: 35px;
                        text-align: center;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 20px;
                        margin-bottom: 10px;
                        margin: 0 auto;
                        button {
                            background: #0099ff;
                            border: solid 1px #0099ff;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .accessories_result {
            margin-top: 20px;
            min-height: 480px;
            height: auto;
            .list_info_pagination {
                display: flex;
                width: 100%;
                text-align: center;
                justify-content: space-between;
                line-height: 32px;
                padding-bottom: 30px;
                padding-top: 10px;
                .enquiryContainer {
                    display: flex;
                    span {
                        width: 131px;
                        height: 38px;
                        background: #0099ff;
                        line-height: 38px;
                        color: #fff;
                        border-radius: 5px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
                .accessoriesPagination {
                    display: flex;
                    margin-left: 110px;
                    .first_button {
                        margin-right: 10px;
                        display: inline-block;
                        cursor: pointer;
                        font-size: 15px;
                    }
                    .last_button {
                        margin-left: 10px;
                        display: inline-block;
                        cursor: pointer;
                        font-size: 15px;
                    }
                    .ban {
                        cursor: not-allowed;
                    }
                }
                .reserved {
                    span{
                        display: inline-block;
                        width: 131px;
                        height: 38px;
                        background: #0099ff;
                        line-height: 38px;
                        color: #fff;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.information_main{
    width: 64%;
    margin: 40px auto;
    // height: 800px;
    background-color: #F3F3F3;
    .banner{
        width: 60%;
        height: 400px;
        background-color: #666;
        display: inline-block;
        margin-left: 22%;
        margin-top: 20px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .information_list{
        display: block;
        width: 60%;
        // height: 800px;
        display: inline-block;
        margin-left: 22%;
        margin-top: 10px;
        background-color: #fff;
        border:1px solid #ccc;
        margin-bottom: 20px;
        border-radius: 8px;
        .information_list_title{
            cursor: pointer;
            width: 95%;
            height: 35px;
            border-bottom: 1px dashed #666;
            margin: 15px auto;
            display: block;
            color: black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
   
}
.Recharges{
    background-color: #ff6b37;
    display: inline-block;
    width: 100px;
    height: 25px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    line-height: 25px;
    margin-left: 10px;
    cursor: pointer

}
.list_info_pagination {
    display: flex;
    width: 1125px;
    text-align: center;
    justify-content: center;
    line-height: 32px;
    padding-bottom: 30px;
    padding-top: 10px;
    margin: 0 auto;
    .first_button {
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
    }
    .last_button {
        margin-left: 10px;
        display: inline-block;
        cursor: pointer;
    }
}
        // 品牌推荐
        .brand_lists {
            height: auto;
            width: 64%;
            margin: 0 auto;
            margin-top: 30px;
            border-top: solid 1px #eee;
            border-bottom: solid 1px #eee;
            .brand_title {
                display: flex;
                padding-top: 20px;
                span {
                    display: inline-block;
                    padding: 5px 20px;
                    flex-shrink: 0;
                    cursor: pointer;
                    &.active_01 {
                        color: #ff0000;
                    }
                    &.actives {
                        color: #ff6b37;
                        background: #f8f8f8;
                    }
                }
                .brand_listName {
                    padding-left: 0px;
                }
            }
            .list_detail {
                display: flex;
                width: 100%;
                height: 90px;
                background: #f8f8f8;
                span {
                    display: inline-block;
                    padding: 0 10px;
                    cursor: pointer;
                    &.actives {
                        color: #ff6b37 !important;
                    }
                }
            }
            .vacancy {
                width: 100%;
                height: 20px;
            }
        }
        .active {
                
            color: #ff6b37 !important;
        }

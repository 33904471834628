a {
    text-decoration: none;
}

.BusinessQuery_container {
    width: 100%;
    min-width: 1300px;
    background: #fff;
    border-top: solid 2px #ccc;
    .BusinessQuery {
        width: 1500px;
        margin: auto;
        .search_accessories {
            border: solid 1px #ccc;
            border-top: none;
            display: flex;
            height: 180px;
            align-items: center;
            width: 100%;
            .search_content {
                display: flex;
                flex-wrap: wrap;
                span {
                    width: 70px;
                    text-align: right;
                    display: inline-block;
                    margin-right: 8px;
                }
                input {
                    width: 250px;
                    height: 30px;
                    border: 0;
                    outline: none;
                    border: solid 1px #ccc;
                    margin-top: 8px;
                    padding-left: 8px;
                }
                .BusinessQueryData {
                    text-align: left;
                    margin-top: 8px;
                    .separator {
                        font-weight: normal;
                        display: inline-block;
                        margin-right: 5px;
                    }
                    input {
                        margin-top: 0;
                        width: auto;
                        padding-left: 12;
                        border-radius: 0;
                    }
                }
            }
            .templateFunction {
                display: flex;
                margin-right: 20px;
                justify-content: flex-end;
                margin-top: 20px;
                .downloadTemplate,
                .toLead {
                    width: 90px;
                    background: #0099ff;
                    display: inline-block;
                    height: 35px;
                    border-radius: 3px;
                    color: #fff;
                    line-height: 35px;
                    text-align: center;
                    font-size: 14px;
                    cursor: pointer;
                    margin-left: 20px;
                    button {
                        background: #0099ff;
                        border: solid 1px #0099ff;
                        color: #fff;
                    }
                }
            }
            p {
                display: flex;
                justify-content: flex-end;
            }
            .search_btn {
                width: 90px;
                background: #0099ff;
                display: inline-block;
                height: 35px;
                border-radius: 3px;
                color: #fff;
                line-height: 35px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                margin-right: 20px;
            }
        }
        .accessories_result {
            margin-top: 20px;
            min-height: 480px;
            height: auto;
            .list_info_pagination {
                display: flex;
                width: 100%;
                text-align: center;
                justify-content: center;
                line-height: 32px;
                padding-bottom: 30px;
                padding-top: 10px;
                .first_button {
                    margin-right: 10px;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 15px;
                }
                .last_button {
                    margin-left: 10px;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 15px;
                }
                .ban {
                    cursor: not-allowed;
                }
            }
        }
    }
}
.container-line {
    border-top: solid 2px #ccc;

    .cb-home {
        min-width: 1200px;
        background: #fff;
        width: 1200px;
        height: auto;
        margin: 0 auto;

        .culture {
            background: url('./images/bg.png');
            background-size: 100% 100%;
            min-height: 520px;
            border-bottom: solid 1px #1890ff;
        }

        .downloadWrap {
            border-bottom: solid 1px #1890ff;
            height: 585px;
        }

        .downloadContainer {
            display: flex;
            margin: auto;
            justify-content: space-around;

            .contactNumber {
                padding-left: 55px;

                p {
                    font-size: 22px;
                    margin-bottom: 0px;

                    span {
                        display: inline-block;

                        &:nth-child(2) {
                            margin-left: 30px;
                        }
                    }
                }
            }

            h1 {
                text-align: center;
            }

            .download-block {
                padding: 50px;

                .officialAccounts {
                    width: 190px;
                    height: 180px;
                    position: relative;
                    top: -10px;
                }

                dl {
                    text-align: center;

                    dd {
                        width: 180px;
                        height: 180px;
                        text-align: center;
                    }

                    dt {
                        span {
                            margin-top: 20px;
                            display: block;

                            &:nth-child(2) {
                                a {
                                    display: block;
                                    color: #53c7f8;
                                    margin-top: 15px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                img {
                    width: 180px;
                    height: 154px;
                }
            }
        }

        .packDownload {
            padding-bottom: 150px;
            min-height: 515px;

            h1 {
                margin-bottom: 20px;
            }
        }
    }

    .backEnd {
        height: 60px;
        display: flex;
        justify-content: flex-end;

        a {
            width: 140px;
            height: 40px;
            line-height: 40px;
            background: #1890ff;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            margin-top: 16px;

        }
    }
}
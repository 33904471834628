.Mycar {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;


    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .mycartable {
        border: 1px solid #eee;
        width: 960px;
        height: calc(100vh - 145px);
        min-height: 700px;
        padding: 20px;

        .addcar {
            width: 228px;
            margin-bottom: 10px;
        }

        .list_info_pagination {
            width: 100% !important;
        }

        .operation {
            button {
                // background-color: #1890ff;
                // border-color: #1890ff;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
                // color: white;
                padding: 0 5px;
            }
        }
    }
}

.addcar {

    .ant-input,
    .ant-select-search__field {
        height: 32px;
        vertical-align: bottom !important;
    }

    .ant-input-group.ant-input-group-compact {
        width: 74%;
        display: inline-block;
    }

    div {
        .carId {
            display: inline-block;
            vertical-align: -webkit-baseline-middle;
        }
    }

    .freegetcarnum {
        margin-top: 10px;

        button {
            width: 100%;
            background: white;
            border-radius: 5px;
            height: 32px;
            border: 1px solid;
        }
    }

    .carnum {
        margin-top: 10px;

        input {
            width: 74%;
            border: 1px solid;
        }
    }

    .enginenum {
        margin-top: 10px;

        input {
            width: 74%;
            border: 1px solid;
        }
    }

    .colose {
        margin-top: 10px;

        button {
            margin: 0 auto;
            padding: 10px 0px;
            width: 48%;
            cursor: pointer;
            color: black;
            text-align: center;
            border: 0.5px solid grey;
            margin-right: 5px;
        }
    }

    .ant-modal-header .ant-modal-title {
        text-align: center;
    }

    .moneyList {
        height: 250px;
    }

    .rechargeamount {
        border: 1px solid #333;
        width: 129px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        float: left;
        margin-left: 10px;
        margin-bottom: 11px;

    }


}
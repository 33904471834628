.sellerOrderPage{
    p{
        margin-bottom: 0px;
    }
    .sellerOrderContainer {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .buyerOrderCatalog {
            width: 15%;
            border: solid 1px #ccc;
            height: 756px;
            border-radius: 3px;
            .title{
                display: flex;
                justify-content: center;
                border-bottom: solid 1px #ccc;
                span {
                    height: 55px;
                    line-height: 55px;
                }
            }
            p {
                display: flex;
                margin-bottom: 0px;
                span {
                    width: 100%;
                    text-align: center;
                    height: 55px;
                    line-height: 55px;
                    cursor: pointer;
                }
                .myspan{
                    color: #368DF4;
                    background: #ecf5ff;
                }
            }
        }
        
    }
    .sellerOrderContent {
        flex: 1;
        height: 756px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-left: 10px;
        // .headerTitle{
        //     display: flex;
        //     border-bottom: solid 1px black;
        //     height: 41px;
        //     line-height: 41px;
        //     text-align: center;
        //     span{
        //         background: white;
        //         border: 1px solid #ccc;
        //         border-bottom: 1px solid #fff;
        //         border-radius:  3px 3px 0 0;
        //         padding: 3px 5px ;
        //         cursor: pointer;
        //     }
        //     .ant-tabs {
        //         width: 100%;
        //     }
        // }
    }
}
.Broadside{
    width: 180px;
    border: solid 1px #ccc;
    height: 756px;
    border-radius: 3px;
    margin-top: 0 !important;
    .title {
        // display: flex;
        // justify-content: center;
        // border-bottom: solid 1px #ccc;
        width: 180px;
        text-align: center;
        color: #111;
        span {
            height: 55px;
            line-height: 55px;
        }
    }
    p{
        display: flex;
        margin-bottom: 0px;
        border-bottom: 1px solid #eee;
        display: block;
        text-align: center;
        height: 44px;
        line-height: 44px;
        cursor: pointer;
    }
    .title-p{
        display: flex;
        margin-bottom: 0px;
        border: 1px solid #eee;
        display: block;
        text-align: center;
        height: 44px;
        line-height: 44px;
        background: #ECF5FF;
        color:#368DF4;
       
    }
}
.productDetailPage {
    .productInfoContainer {
        width: 1125px;
        margin: 60px auto;
    
        .catalogNav {
            
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            span {
                border-bottom: solid 1px #0099FF;
                color: #0099FF;
                height: 40px;
                line-height: 40px;
            }
            .none {
                color: #ccc;
            }
        }
        .productContent {
            border: solid 1px #ccc;
            padding: 2%;
            .productInfoContent {
                margin-top: 15px;
                border: solid 1px #ccc;
                padding: 2%;
                display: flex;
                .productPic {
                    width: 35%;
                    .bigPic {
                        width: 80%;
                        height: 300px;
                        img {
                            width: 104%;
                            height: 100%;
                            display: inline-block;
                        }
                    }
                    .picList {
                        width: 80%;
                        display: flex;
                        margin-top: 10px;
                        justify-content: center;
                        .active {
                            border: solid 2px #FF6B37;
                        }
                        span {
                            margin-right: 10px;
                            width: 16%;
                            height: 40px;
                            cursor: pointer;
                            img {
                                height: 100%;
                                width: 100%;
                            }
                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                    }
                }
                .productInfo {
                    width: 65%;
                    .copyText {
                        color: red;
                        display: inline-block;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                    ul {
                        li {
                            display: flex;
                            margin-top: 5px;
                            span {
                                &:nth-child(1) {
                                    width: 100px;
                                }
                            }
                            .chooseNumContainer {
                                display: flex;
                                margin-right: 10px;
                                height: 35px;
                                align-items: center;
                                span {
                                    width: 25px;
                                    height: 25px;
                                    line-height: 25px;
                                    background: #b4bbc5;
                                    color: #fff;
                                    text-align: center;
                                    border-radius: 5px;
                                    cursor: pointer;
                                }
                                input {
                                    width: 60px;
                                    height: 25px;
                                    border: solid 1px #ccc;
                                    margin-left: 8px;
                                    margin-right: 8px;
                                    text-align: center;
                                }
                            }
                            .addShopCart {
                                width: 120px;
                                height: 35px;
                                line-height: 35px;
                                background: #FF6B37;
                                color: #fff;
                                text-align: center;
                                border-radius: 5px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .accuracyInfoContent {
                border: solid 1px #ccc;
                padding: 2%;
                .basicContainer {
                    width: 100%;
                    border: none;
                    border-top: solid 1px#ccc;
                    border-left: solid 1px#ccc;
                    tr {
                        // display: flex;
                        td {
                            width: 25%;
                            border-bottom: solid 1px#ccc;
                            border-right: solid 1px#ccc;
                            height: 35px;
                            line-height: 35px;
                            &:nth-child(1) {
                                width: 20%;
                                text-align: center;
                            }
                            &:nth-child(2) {
                                width: 30%;
                                padding-left: 10px;
                            }
                            &:nth-child(3) {
                                width: 15%;
                                text-align: center;
                            }
                            &:nth-child(4) {
                                width: 45%;
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
            .standardCarContainer {
                width: 100%;
                border: none;
                border-top: solid 1px#ccc;
                border-left: solid 1px#ccc;
                text-align: center;
                tr {
                    th {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 43px;
                        line-height: 43px;
                        font-weight: normal;
                    }
                }
                tr {
                    // display: flex;
                    td {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 43px;
                        line-height: 43px;
                    }
                }
            }
            .swapGroupContainer {
                width: 70%;
                border: none;
                border-top: solid 1px#ccc;
                border-left: solid 1px#ccc;
                text-align: center;
                tr {
                    th {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 43px;
                        line-height: 43px;
                        font-weight: normal;
                    }
                }
                tr {
                    // display: flex;
                    td {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 43px;
                        line-height: 43px;
                    }
                }
            }
        }
    }
}
.catalogNav-span{
    cursor: pointer;
}
.image-li{
    float: left;
    width: 50px;
    height: 50px;
    border: 1px solid #eee;
    .image-arr{
        width: 50px;
        height: 50px;
        background-size: cover;
    }
}
.addshopCart1{
    color:#1890ff;
    cursor: pointer;
}
.warpper-chai{
    border-top: solid 2px #ccc;
    .warper_Disassembly{
        width: 1200px;
        margin: 0 auto;
    }
    .title_Disassembly{
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
        margin-top: 1em;
        margin-bottom: 0;
    }
    .Disassembly_con{
        
        .Disassembly_con_top{
            border: 1px solid #eee;
            overflow: hidden;
            .Disassembly_con_top_cld{
                display: flex;
            }
            .Disassembly_con_img{
                flex:2;
                .img_{
                    width: 100%;
                    height: 100%;
                }
            }
            .Disassembly_con_btn{
                padding: 10px;
                line-height: 20px;
                font-size: 16px;
                font-family: '微软雅黑';
            }
            .Disassembly_con_p{
                padding: 10px;
                flex: 3;
                line-height: 20px;
                font-size: 16px;
                font-family: '微软雅黑';
                padding-top: 20px;
               
               
            }
            .Disassembly_con_btn{
                float: right;
            }
            p{
                margin-top: 20px;
                text-indent: 2em;
            }
            .tx4{
                text-indent:4em;
            }
        }
    }
    .upstream{
        .upstream_row{
          
            .upstream_li{
                text-align: center;
                border: 1px solid #000;
                padding: 10px;
                min-height: 270px;
                .upstream_img{
                    text-align: center;
                    .upstream_img_{
                        width: 120px;
                        height: 120px;
                    }
                    .upstream_img_p{
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
                .info_p{
                    padding: 10px;
                    line-height: 20px;
                    border-radius: 8px;
                    background-color: #eee;
                    color: #000;
                    margin: 10px 0 ;
                }
            }
        }

    }
    .mt-20{
        margin-top: 20px;
    }
  
}
.storeFooter {
    background: rgba(242, 242, 242, 1);
    height: 150px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    .storeFooterContent {
        width: 1125px;
        margin: auto;
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        .storePic {
            img {
                width: 53px;
                height: 53px;
                margin-right: 10px;
            }
            span {
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
    .copyRight {
        width: 1125px;
        margin: auto;
        text-align: center;
    }
}
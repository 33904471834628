.usercenterbox {
    padding: 0 0 0 329px;
    
}

.usercenter {
    width: 16%;
    float: left;
    border: 1px solid #eee;
    margin-top: 2px;
    text-align: center;
   
    .usercentertitle {
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #eee;
    }

    li {
        margin-top: 5px;
        
        /deep/ .active {
            border-bottom: 0px solid red !important;

        }
       
    }
}
.heightPage{
    // margin: 10px 0;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #eee;
  
    
   
}
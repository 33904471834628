.Shippingaddressbox {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;

    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .Shippingaddress {
        border: 1px solid #eee;
        height: calc(100vh - 150px);
        padding: 20px;

        .addShippingaddress {
            border: 1px solid #ccc;
            border-radius: 10px;
            width: 150px;
            padding: 8px 10px;
            text-align: center;
            font-size: 14px;
        }
    }

}
.Cbfooter {
    background: #f5f5f5;
    span {
        color: #000;
    }
    .official-container {
        .official-title {
            text-align: center;
            h1 {
                font-size: 24px;
            }
        }
        .official-main {
            display: flex;
            width: 960px;
            margin: 0 auto;
            margin-top: 21px;
            justify-content: space-between;
            .officialUrl {
                img {
                    width: 140px;
                }
                p {
                    width: 140px;
                    text-align: center;
                    margin-top: 5px;
                }
            }
            .officialUrlPhone,
            .officialUrlLocation {
                display: flex;
                align-items: baseline;
                .companyPhone,
                .companyLocation {
                    position: relative;
                    top: -2px;
                    display: flex;
                    flex-direction: column;
                    .locationGap {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    .footer-bottom-in {
        line-height: 40px;
        width: 1200px;
        margin: 0 auto;
        margin-top: 60px;
        text-align: center;
        a {
            cursor: pointer;
            color: #2878ff;
        }
    }
}
.shopHome {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  .shopClassify {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 408px;
    .classify-left {
      position: relative;
      width: 250px;
      height: 408px;
      background: #333333;
      .left-li {
        position: relative;
        width: 100%;
        height: 34px;
        color: #fff;
        text-align: left;
        line-height: 34px;
        box-sizing: border-box;
        padding: 0 14px;
        >span {
          margin-right: 20px;
          color: #ff6b37;
        }
      }
      .left-li-actived {
        background: #fff;
        color: #ff6b37;
      }
      .left-box {
          position: absolute;
          min-width: 300px;
          top: 0;
          left: 200px;
          background: #fff;
          box-shadow: 0px 4px 10px #ccc;
          color: #333;
          padding: 10px;
          z-index: 99;
          div{
            display: inline-block;
            em {
              font-style: normal;
              padding: 6px 10px;
              cursor: pointer;
            }
            em:hover {
              color: #ff6b37;
            }
            .em-actived {
              color: #ff6b37;
            }
            >span {
              color: #ff6b37;
            }
          }
          
        }
      
    }
    .classify-right {
      width: 100%;
    }
  }
  h1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background-color: #ddd;
    margin: 0;
    margin-top: 30px;
    box-sizing: border-box;
    border: none;
    border-left: 4px solid #ff6b37;
    padding: 0 20px;
    color: #ff6b37;
    font-size: 18px;
    span:nth-child(2) {
      cursor: pointer;
    }
  }
}
.loading-spin {
  margin: 100px auto;
}
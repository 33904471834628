.managementpage{
    // height:  700px;
    // border: 1px solid #eee;
    .managementtitle{
        width: 100%;
        height: 130px ;
        border: 1px solid #eee;
        
        .ant-select{
            width: 150px !important;
        }
        .btn{
            position: absolute;
            right: 10px;
            margin-left: 10px;
        }
        .myinput{
            height: 30px;
            border: 0;
            outline: none;
            border: solid 1px #ccc;
            margin-top: 8px;
            padding-left: 8px;
            border-radius: 4px;
        }
        .ant-select-selection--single {
            height: 30px ;
            border: solid 1px #ccc;
            border-radius: 4px;
            
        }
       
    }
    .managementcontent{
        width: 100%;
        height: 476px;
        font-size: 12px;
        margin-bottom: 17px;
    }
    .link{
        color: #1890ff;
    }
    
}
.managementtitle{
    position: relative;
    .row-p{
        width: 100%;
        
        margin-left: 20px;
        margin-top: 20px;
        position: relative;
        .col{
            width: 249px;
            height: 30px;
            .col-span{
                font-size: 12px;
                width: 100px;
            }
            .col-input{
                width: 152px;
                height: 31px;
                margin-left: 19px;
                border-radius: 5px;
                border: 1px solid rgba(104, 100, 100, 0.199);
            }
            .col-input1{
                margin-left: 11px;
            }
            .col-input:hover{
                border: 1px solid rgb(88, 157, 248);
            }
        }
        .col-1{
            margin-top: 20px;
            width: 25%;
            height: 40px;
           
            .col-span{
                font-size: 12px;
                width: 100px;
            }
        }
        .col-2{
            width: 459px;
            position: absolute;
         
            right: 52px;
            .big{
                width: 150px;
            }
        }
    }
    .searchButton{
        position: absolute;
        bottom: 5px;
        right: 30px;
    }
}

.bu-p{
    padding-top: 10px;
    line-height: 20px;
    
}
.buyerOrderListTable1{
   width: 100%;
   border: 1px solid #e8e8e8;
   border-right: 0;
//    height: 440px;
}
.ant-table-tbody > tr > td{
    width: 100%;
    padding: 0 !important;
}
.ant-table-small > .ant-table-content > .ant-table-body{
    margin: 0 !important;
}
.ant-table-small{
    border:0 !important;
}

.ant-table-thead > tr, .ant-table-tbody > tr{
    height: 43px;
}
.management-p{
    position: relative;
    left: -1px;
    // border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 2px solid white;
    font-weight: bold;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 14px;
}


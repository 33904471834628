.shoppoingCartPage {
    .shoppingContainer {
        width: 1125px;
        margin: auto;
        .shoppingContent {
            margin-bottom: 10px;
            .shoppingItem {
                margin-top: 15px;
                .storeInfo {
                    height: 44px;
                    line-height: 44px;
                    margin-bottom: 15px;
                    background: rgba(242, 242, 242, 1);
                    display: flex;
                    .allElection {
                        margin-left: 10px;
                        input {
                            position: relative;
                            top: 2px;
                        }
                    }
                    p {
                        display: flex;
                        span {
                            margin-left: 30px;
                        }
                    }
                }
                .shoppingList {
                    .quantityContainer {
                        display: flex;
                        height: 30px;
                        span {
                            width: 25px;
                            height: 25px;
                            line-height: 25px;
                            border: solid 1px #ccc;
                            background: rgba(0, 0, 0, .5);
                            color: #fff;
                        }
                        input {
                            width: 60px;
                            height: 25px;
                            margin-left: 8px;
                            margin-right: 8px;
                            border: solid 1px #ccc;
                            border-radius: 3px;
                            text-align: center;
                        }
                    }
                    .shoppingTable {
                        height: auto;
                        min-height: 0px!important;
                        .singleChecked {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    .delete {
                        color: #0099FF;
                        cursor: pointer;
                    }
                    p {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 15px;
                        margin-right: 20px;
                    }
                }
            }
        }
        .line {
            height: 1px;
            background: #ccc;
        }
        .settlementContent {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            margin-bottom: 120px;
            .batchDetele {
                width: 100px;
                height: 40px;
                line-height: 40px;
                color: #fff;
                background: #ff6b37;
                border-radius: 5px;
                text-align: center;
                margin-left: 8px;
                cursor: pointer;
            }
            .finishOrder {
                position: absolute;
                top: 0;
                right: 0;
                margin-right: 8px;
                display: flex;
                flex-direction: column;
                span {
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    color: #fff;
                    background: #ff6b37;
                    border-radius: 5px;
                    text-align: center;
                    margin-left: 160px;
                    cursor: pointer;
                }
            }
        }
    }
}
.tubePlacetoolti{
    position: relative;
    margin: 0 auto;
    line-height: 30px;
    .tooltip{
       text-align: center;
        padding-top: 20px;
    }
    .tooltipSpan{
        position: absolute;
        top: 173%;
        left: 42%;
        padding: 5px 15px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid #ff6b37;
    }
}
// .Cart-big{
//     height: 100px;
//     .Cart{
//         height: 100px;
//     }
// }
.addshopCart1{
    cursor: pointer;
    color: #1890ff;
}

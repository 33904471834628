.BusinessmanagementBox {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;

    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .Businessmanagementcenter {
        border: 1px solid #eee;
        height: calc(100vh - 150px);
        padding: 20px;
        .functionButton{
            .tipTitle {
                font-style: normal;
                color: #f00;
                text-align: center;
            }
            .downloadTemplate,
            .toLead {
                width: 130px;
                background: #0099ff;
                display: inline-block;
                height: 35px;
                border-radius: 3px;
                color: #fff;
                line-height: 35px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                margin-left: 20px;
                margin-bottom: 10px;
                margin: 0 auto;
                button {
                    background: #0099ff;
                    border: solid 1px #0099ff;
                    color: #fff;
                }
            }
        }
        .BusinessQuery_container {
            /* width: 100%; */
            min-width: 100%;
            background: #fff;
            border-top: solid 1px #ccc;
            .BusinessQuery {
                width: 100%;
                .search_accessories{
                    width: 100%;
                    .search_content{
                        width: 100%; 
                        .firstTR{
                            width: 20%;
                            input{
                                width: 56%!important;
                            }
                            div{
                                width: 56%!important;
                                div{
                                    width: 100%!important;
                                }
                            }
                        }    
                   }
                }
            }
        }
    }
}
.ant-select-dropdown-menu::-webkit-scrollbar {
    display: none;
}
.personalStoreNav {
    border-bottom: solid 1px #ccc;
    height: 60px;
    line-height: 60px;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    min-width: 1125px;
    background: #fff;
    .navContainer {
        display: flex;
        width: 1125px;
        margin: auto;
        justify-content: space-between;
        .storeName {
            font-size: 22px;
          
        }
        .storeOptions {
            .storeOption {
                display: flex;
                a {
                    color: black;
                    margin-left: 45px;
                    width: 70px;
                    height: 60px;
                    text-align: center;
                }
            }
        }
    }
}
.active {
    color: #ff6b37 !important;
    border-bottom:2px solid  #ff6b37  !important;

}
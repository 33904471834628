.companyInfoContainer {
    display: flex;
    .storePic {
        width: 30%;
        .bigPic {
            width: 100%;
            height: 300px;
            img {
                display: inline-block;
                width: 100%;
                height: 100%;
            }
        }
        .picList {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            .active {
                border: solid 2px #FF6B37;
            }
            span {
                margin-right: 10px;
                width: 16%;
                height: 50px;
                cursor: pointer;
                img {
                    height: 100%;
                    width: 100%;
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
    .companyInfoContent {
        width: 70%;
        padding-left: 60px;
        .companyInfoTable {
            width: 100%;
            border: none;
            border-top: solid 1px#ccc;
            border-left: solid 1px#ccc;
            text-align: center;
            tr {
                // display: flex;
                td {
                    border-bottom: solid 1px#ccc;
                    border-right: solid 1px#ccc;
                    height: 43px;
                    line-height: 43px;
                    &:nth-child(1) {
                        width: 30%;
                    }
                    &:nth-child(2) {
                        width: 70%;
                    }
                }
                .toPersonalStore:hover{
                    cursor: pointer;
                    color: #1890ff;
                }
            }
        }
    }
}
.li{
    float: left;
    width: 60px;
    height: 60px;
    margin-left: 10px;
    .li-image{
        width: 60px;
        height: 60px;
        
    }
}

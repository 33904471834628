.NewArrivals_lists{
    position: relative;
    width: 1200px;
    min-height: 25s0px;
    margin: 40px auto;
    margin-top: 0;
    padding: 15px;
    border:1px solid #ddd;
    box-shadow: 0px 4px 8px #ddd;
    .loading-spin {
        position: absolute;
        left: 50%;
    }
     .lists_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
    }
    .productMain{
        width: 19%;
        display: inline-block;
        margin-right: 1.2%;
        .imgBoxs {
            width: 220px;
            height: 220px;
            img{
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
        
        p{
            padding: 0px;
            margin-top: 5px;
        }
         p:nth-child(3) {
           display: inline-block;

            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
         p:nth-child(3):hover {
            color: #1890ff;
            cursor: pointer;

        }
    }
    .productMain:nth-child(5n+5) {
        margin-right: 0;
    }
}
.top_sort{
    position: relative;
    width: 1200px;
    height: 48px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:nth-child(1) {
        span:nth-child(1) {
            color: #ff6b37;
            cursor: pointer;
        }
        span:nth-child(2) {
            color: gray;
        }
    }
    div:nth-child(2) {
        span{
            cursor: pointer;
        }
        span:nth-child(1) {
            margin-right: 40px;
        }
    }
}
.header-container {
    position: fixed;
    z-index: 999;
    right: 0;
    left: 0;
    min-width: 1300px;
    height: 65px;
    line-height: 65px;
    background: #fff;

    // border-bottom: solid 1px #ccc;
    .navMenu {
        display: flex;
        flex: 1;
        justify-content: space-between;
    }

    .userOperation {
        // position: absolute;
        // right: -165px;
        // top: -2px;

        margin-top: 10px;
        font-size: 17px;
        padding-left: 25px;

        .userPhone {
            color: #999999;
        }

        span {
            margin-right: 8px;
            margin-left: 8px;
            color: #409eff;
            cursor: pointer;
        }
    }

    .header-content {
        position: relative;
        display: flex;
        width: 1300px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
        height: 65px;
        line-height: 39px;

        .header-navbar-menu {
            line-height: 63px;
            height: 65px;
        }
    }

    .menu {
        margin-right: 100px;
        background: transparent;

        .menu-item {
            font-size: 14px;
            font-weight: 600;
            color: #fff;

            &:hover,
            a:hover {
                color: hsla(0, 0%, 100%, .7);
            }
        }

        .menu-item-selected {
            color: hsla(0, 0%, 100%, .7);
        }
    }

    .ticket-button {
        font-size: 12px;
        font-weight: 600;
        line-height: 28px;
        position: absolute;
        right: 0;
        display: block;
        width: 82px;
        padding: 0 16px;
        transition: all .3s;
        transition: all .3s ease-in-out;
        color: #fff;
        border: 1px solid #fff;

        &:hover {
            color: #236cff;
            background: #fff;
        }
    }
}

// .popup{
//     width: 80px;
//     height: 100px;
//     border: 1px solid #000;
//     background: red;
// }
.myspan {
    position: absolute;
    top: 11px;
    right: -43px;
    width: 20px;
    height: 20px;
    background-image: url(./image/xia.png);
    background-size: 100% 100%;
    cursor: pointer;

}

// .myspan:hover{
//     width: 100px;
//     height: 150px;
//     background: white;
// }
.popup {
    overflow: hidden;
    position: absolute;
    top: 23px;
    left: -37px;
    width: 100px;
    height: 150px;
    border: 1px solid #eee;
    background: white;

    .popup-p {
        display: block;
        overflow: hidden;
        text-align: center;
        line-height: 30px;
        border: 1px solid #eee;
    }
}

.NavLink {
    position: relative;
    width: 150px;
    height: 50px;
    display: block;

    .readyLogin {
        position: absolute;
        top: 5px;
        left: 25px;
    }

    .otherList li a {

        display: block;
        margin: -5px -12px;
        padding: 5px 12px;
        color: rgba(0, 0, 0, 0.65);
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .myP {
        position: absolute;
        top: 10px;
        left: -3px;
        width: 30px;
        height: 30px;
        display: block;
        background-image: url(./image/xiaohuang.png);
        background-size: 100% 100%;
        cursor: pointer;

        .myp-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            background: red;
            border-radius: 50%;
            color: white;

        }
    }

    .ant-dropdown-menu {
        background-color: red;
    }

}

#otherList li a {

    display: block;
    margin: -5px -12px;
    padding: 10px 26px;

    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.textceng {
    text-align: center;
}
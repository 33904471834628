
.sellerOrderContent {
    flex: 1;
    height: 756px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 10px;
        .card-title{
            margin: 150px auto;
            width: 300px;
            height: 200px;
            
            .card-title1{
                position: relative;
                height: 30px;
               
                background: #ff6837;
                color: white;
                .card-span{
                   font-weight: bold;
                    text-align: center;
                    color: white;
                }
                .card-guan{
                    position: absolute;
                    right: 9px;
                   top: 4px;
                   font-weight: bold;
                }
            }
        }
        .card-content{
            position: relative;
            height: 170px;
            border: 1px solid #000;
            p{
                text-align: center;
                padding-top: 10px;
                color: #ff6837;
            }
            .pone{
                display: block;
                text-align: center;
                padding-top: 20px;
                color: #ff6837;
            }
           .content-span{
               position: absolute;
               border: 1px solid #ff6837;
               border-radius: 4px;
               padding: 5px 10px;
               bottom: 4px;
               left: 23px;
           }
           .content-span1{
               position: absolute;
               bottom: 4px;
               right: 27px;
               border-radius: 4px;
               padding: 5px 10px;
               border: 1px solid #ff6837;
           }
        }
    
}
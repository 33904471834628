.Recharge{
    width: 1200px;;
    height: 800px;
    margin: 40px auto;
    background-color: #F3F3F3;
    .Recharge_main{
        width: 700px;
        height: 600px;
        display: inline-block;
        margin-top: 30px;
        margin-left: 20%;
        background-color: #fff;
        .next{
            margin-top: 10px;
            margin-left: 10px;
        }
        .Recharge_title{
            text-align: center;
        }
        input{
            margin:  0 auto;
            display: block;
            border: 1px solid #999999;
            height: 30px;
            width: 200px;
            font-size: 10px;
            text-align: center;
        }
        .Recharge_btn{
            width: 91px;
            height: 30px;
            margin: 22px auto;
            line-height: 25px;
            background-color: #999999;
            text-align: center;
            color: white;
            border-radius: 8px;
        }
        .Recharge_btns{
            width: 91px;
            height: 30px;
            margin: 22px auto;
            line-height: 25px;
            background-color: #ff6b37;
            text-align: center;
            color: white;
            border-radius: 8px;
        }
        .line{
            width: 100%;
            // margin-top: 50px;
            border-top: 1px solid #999999;;
        }
        .tab_title{
            width: 220px;
            margin: 0 auto;
            height: 50px;
            span{
                margin-top: 10px;
                width: 80px;
                text-align: center;
                line-height: 34px;
            }
        }
        .fl{
            float: left;
        }
        .fr{
            float: right;
        }
        .tab_a{
            width: 100%;
        }
        .tab_a_main{
            width: 80%;
            margin: 0 auto;
            height: 40px;
        }
        .tab_a_mains{
            width: 80%;
            margin: 0 auto;
            height: 40px;
            border-bottom: 1px  dashed ;
            line-height: 38px;
        }
        .tab_b{
            width: 100%;
        }
        .tab_b_main{
            width: 90%;
            margin: 0 auto;
            height: 40px;
            // border-bottom: 1px  dashed ;
            line-height: 38px;


        }
        .tab_b_main span{
            display: inline-block;
            // displayd: flex;
            // flex-wrap: nowrap

        }
        .tab_b_mains{
            width: 90%;
            margin: 0 auto;
            height: 40px;
            border-bottom: 1px  dashed ;
            line-height: 38px;
        }
        .tab_b_mains span{
            display: inline-block;
            // displayd: flex;
            // flex-wrap: nowrap

        }
        // .tab_b_mains{
        //     width: 90%;
        //     margin: 0 auto;
        //     height: 40px;
        //     border-bottom: 1px  dashed ;
        //     line-height: 38px;
        //     border-bottom: 1px  dashed ;
        // }
       
    }

}

.title_top{
    width: 80%;
    margin: 0 auto;
}
.add{
    // color: #a59e9b;
    border-bottom: 2px solid #ff6b37;
}
.list_info_paginations {
    display: flex;
    // width: 1125px;
    text-align: center;
    justify-content: center;
    line-height: 32px;
    padding-bottom: 30px;
    padding-top: 50px;
    .first_button {
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
    }
    .last_button {
        margin-left: 10px;
        display: inline-block;
        cursor: pointer;
    }
}
.toBeConfirmedPage {
    p {
        margin-bottom: 0px;
    }
    .toBeConfirmedContainer {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .buyerOrderCatalog {
            width: 15%;
            border: solid 1px #ccc;
            // height: 756px;
            border-radius: 3px;
            .title {
                display: flex;
                justify-content: center;
                border-bottom: solid 1px #ccc;
                span {
                    height: 55px;
                    line-height: 55px;
                }
            }
            p {
                display: flex;
                margin-bottom: 0px;
                span {
                    width: 100%;
                    text-align: center;
                    height: 55px;
                    line-height: 55px;
                    background: #ecf5ff;
                    color: #368DF4;
                    cursor: pointer;
                }
            }
        }
        .toBeConfirmedContent {
            flex: 1;
            // height: 756px;
            display: flex;
            width: 1002px;
            flex-direction: column;
            box-sizing: border-box;
            margin-left: 30px;
            .title {
                display: flex;
                justify-content: space-between;
                height: 44px;
                line-height: 44px;
                background: #f5f5f5;
                margin-top: 10px;
                padding-left: 30px;
                padding-right: 10px;
            }
            table {
                width: 100%;
                border: none;
                border-top: solid 1px#ccc;
                border-left: solid 1px#ccc;
                text-align: center;
                margin-top: 5px;
                tr {
                    th {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 43px;
                        line-height: 43px;
                        background: #f2f2f2;
                        font-weight: normal;
                        width: 100px;
                    }
                }
                tr {
                    // display: flex;
                    width: 100px !important;
                    height: 35px !important;
                    line-height: 34px !important;
                    td {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 43px;
                        line-height: 43px;
                    }
                }
            }
            .headerTitle {
                display: flex;
                border-bottom: solid 1px #ccc;
                height: 41px;
                line-height: 41px;
                text-align: center;
                span {
                    width: 103px;
                    height: 41px;
                    border: solid 1px #ccc;
                    border-radius: 3px;
                    color: #368DF4;
                }
            }
            .navigation {
                height: 40px;
                line-height: 40px;
                border-bottom: solid 1px #ccc;
                display: flex;
                span {
                    height: 40px;
                    color: #0099FF;
                    border-bottom: solid 1px #0099FF;
                    &:nth-child(3) {
                        color: #000;
                    }
                }
            }
            // .orderDetail {
            //     // table {
            //     //     width: 90%;
            //     // }
            // }
            .shoppingListContainer {
                .totalContainer {
                    display: flex;
                    justify-content: flex-end;
                    p {
                        margin-bottom: 0px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        width: 250px;
                        span {
                            &:nth-child(2) {
                                width: 82px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .buyerOrderList {
       
        margin-top: 15px;
    }
    .TD{
        color: red;
    }
    .totalButton{
        height: 25px;
       margin-left: 86%;
       margin-top: 10px;
        span{
            width: 100px;
            height: 25;
            padding: 0 10px;
            background: #ff6837;
            border: 1px solid #ff6837;
            color: white;
            cursor: pointer;
        }
    }
}
.totalContainer2{
    height: 91px;
    margin-left: 70%;
    margin-top: 10px;
}
.DeliveryRequire{
    margin-top: 10% ;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
.btn{
    position: relative;
    top: -8px;
    margin-left: 165px;
    margin-top: 30px;
}
.ConfirmReceipt{
    width: 100px;
    height: 60px;
    margin-left: 143px;
    margin-top: 47px;
    .Confirmreceipt-p{
        color: #ff6837;
        padding-left: 10px;
    }
    .Confirmreceipt-btn{
        width: 60px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        margin-left: 28px;
        border: 1px solid #ff6837;
        border-radius: 5px;
    }
}
.Conf{
    width: 123px;
    height: 100px;
    margin-left: 143px;
    margin-top: 47px;
    .Confirmreceip{
        color: #ff6837;
        padding-left: 27px;
    }
    .Conf-btn{
        width: 60px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        margin-left: 30px;
        border: 1px solid #ff6837;
        border-radius: 5px;
    }
}
.title {
    // display: flex;
    // justify-content: space-between;
    height: 44px;
    line-height: 44px;
    background: #f5f5f5;
    // margin-top: 15px;
    padding-left: 5px;
    padding-right: 10px;
    color: #ff6837;
    span{
        width: 5px;
    }
}
.goodsPlaceContent {
    display: flex;
    flex-wrap: wrap;
    .goodsPlace {
        background: #f5f5f5;
        border-radius: 8px;
        width: 23%;
        margin-right: 8px;
        height: 130px;
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        p {
            margin-bottom: 0px;
            &:nth-child(3) {
                margin-top: 15px;
            }
        }
        .isDefault {
            display: flex;
            justify-content: space-between;
        }
    }
    .defaullt {
        background: #ff6837;
        p {
            color: #fff;
        }
    }
}
.placeAdministration {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    span {
        width: 116px;
        height: 38px;
        line-height: 38px;
        background: #ff6837;
        color: #fff;
        text-align: center;
        cursor: pointer;
    }
}
// .fahuo{
//     padding: 5px 10px !important;
//     position: absolute;
//     bottom:-100px;
//     left: -100px;
//     text-align: center;
// }
.money{
    color: red;
}
.btn-OK{
    padding: 5px 15px !important;
}
.detail{
    width: 100px;
    overflow: hidden;
}
.spann{
    cursor: pointer;
}
.box{
    margin-left: 80%;
    background: red;
    .box-span{
        padding: 5px 10px;
        border: 1px solid #000;
    }
}
.table-tr{
    width: 100%;
}

.SecuritysetBox {
    width: 55%;
    float: left;
    margin-left: 15px;
    margin-top: 2px;

    .title {
        width: 82px;
        height: 45px;
        /* padding: 5px 10px; */
        background: #b7aeae;
        color: black;
        text-align: center;
    }

    .Securityset {
        border: 1px solid #eee;
        height: calc(100vh - 150px);
        padding: 20px;

        .SecuritysetFrom {
            width: 40%;

            // .ant-col-sm-6 {
            //     // width: 12%;
            // }
        }

        .ant-form-item {
            display: flex !important;
        }

        .verificationCode {
            width: 61%;
        }

        .submitBut {
            Button {
                width: 90%;
                border: 1px solid grey;
                background-color: rgba(255, 255, 255, 0);
                border-radius: 5px;
                height: 30px;
                color: #847474;
            }
        }
    }
}
.toBeConfirmedPage {
    width: 1200px;
    margin: 0 auto;
    p {
        margin-bottom: 0px;
    }
    .toBeConfirmedContainer {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .buyerOrderCatalog {
            width: 15%;
            border: solid 1px #ccc;
            // height: 756px;
            border-radius: 3px;
            .title {
                display: flex;
                justify-content: center;
                border-bottom: solid 1px #ccc;
                span {
                    height: 55px;
                    line-height: 55px;
                }
            }
            p {
                display: flex;
                margin-bottom: 0px;
                span {
                    width: 100%;
                    text-align: center;
                    height: 55px;
                    line-height: 55px;
                    background: #ecf5ff;
                    color: #368DF4;
                    cursor: pointer;
                }
            }
        }
        .toBeConfirmedContent {
            flex: 1;
            // height: 756px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            margin-left: 30px;
            .title {
                display: flex;
                justify-content: space-between;
                height: 32px !important;
                line-height: 32px !important;
                background: #f5f5f5;
                margin-top: 10px;
                padding-left: 30px;
                padding-right: 10px;
            }
            table {
                width: 100%;
                border: none;
                border-top: solid 1px#ccc;
                border-left: solid 1px#ccc;
                text-align: center;
                margin-top: 5px;
                tr {
                    th {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 35px !important;
                        line-height: 34px !important;
                        background: #f2f2f2;
                        font-weight: normal;
                        width: 100px;
                    }
                }
                tr {
                    // display: flex;
                    height: 35px !important;
                    line-height: 34px !important;
                    
                    td {
                        flex: 1;
                        border-bottom: solid 1px#ccc;
                        border-right: solid 1px#ccc;
                        height: 34px !important;
                        line-height: 34px !important;
                    }
                }
            }
            .headerTitle {
                display: flex;
                border-bottom: solid 1px #ccc;
                height: 41px;
                line-height: 41px;
                text-align: center;
                span {
                    width: 103px;
                    height: 41px;
                    border: solid 1px #ccc;
                    border-radius: 3px;
                    color: #368DF4;
                }
            }
            .navigation {
                height: 40px;
                line-height: 40px;
                border-bottom: solid 1px #ccc;
                display: flex;
                span {
                    height: 40px;
                    color: #0099FF;
                    border-bottom: solid 1px #0099FF;
                    &:nth-child(3) {
                        color: #000;
                    }
                }
            }
            .orderDetail {
                // table {
                //     width: 90%;
                // }
            }
            .shoppingListContainer {
                .totalContainer {
                    display: flex;
                    justify-content: flex-end;
                    p {
                        margin-bottom: 0px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        span {
                            &:nth-child(2) {
                                width: 82px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .buyerOrderList {
        margin-top: 15px;
    }
    .TD{
        color: red;
    }
    .myinput{
        overflow: hidden;
        border: 1px solid #eee;
        border-radius: 5px;
        width: 47%;
        height: 30px;
        padding-left: 10px;
    }
    .totalButton1{
        // position: absolute;
        // right: 100px;
        // bottom: 2% ;
        // margin-top: 137px;
        margin-top: 10px;
        margin-left: 88%;
        margin-bottom: 20px;
        span{
            width: 100px;
            height: 25;
            padding: 0 10px;
            background: #ff6837;
            border: 1px solid #ff6837;
            color: white;
            cursor: pointer;
        }
    }
}
.QRCode{
    // display: none;
    height: 300px;
    background: white;
    position: relative;
    h4{
        font-weight: bold;
        color: red;
        text-align: center;
        line-height: 30px;
    }
    .QRCode-qrCode{
        position: absolute;
        top: 20%;
        left: 37%;
    }
    .QRCode-p{
        color: red;
        position: absolute;
        top: 63%;
        left: 29%;
    }
    .QRCode-BTN{
        position: absolute;
        top: 79%;
        left: 36%;
    }
}
.Cancel-box{
    height:230px;
    background: white;
    position: relative;
    .Cancel-p{
        
        text-align: center;
        line-height: 30px;
        display: block;
        color: red;
    }
    .Cancel{
        position: absolute;
        top: 18%;
        left: 10%;
        width: 38%;
        height:140px;
        margin-left: 89px;
        
    }
    .tooltipSpan{
        position: absolute;
        bottom: 26px;
        left: 51px;
        text-align: center;
        display: block;
        margin-left: 100px;
        width: 80px;
        height: 30px;
        border: 1px solid #ff6837;
        border-radius: 5px;
        cursor: pointer;
    }
}

.ant-radio-group{
    float: left;
   
}

.ConfirmReceipt{
    position: relative;
    top: -28px;
    width: 100px;
    height: 60px;
    margin-left: 143px;
    margin-top: 47px;
    .Confirmreceipt-p{
        color: #ff6837;
        padding-left: 10px;
    }
    .Confirmreceipt-btn{
        width: 60px;
        height: 40px;
        line-height: 40px;

        margin-left: 28px;
        border: 1px solid #ff6837;
        border-radius: 5px;
    }
}
.buyerOrderListTable2{
    width: 100%;
    height: 476px;
}
// .list-title{
//     height: 38px !important;
// }
.ant-table-header-column{
    line-height: 1.5;
}
.btn-OK{
    
    
    padding: 5px 15px;
    
}
.detail{
    width: 100px;
    overflow: hidden;
}
// .table-heig{
//     height:  30px;
// }
.spann{
    cursor: pointer;
}
.totalContent-p{
    height: 91px;
    margin-left: 80%;
    padding-top: 10px;
}
.td{
    overflow: hidden;
}
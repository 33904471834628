.detail_container {
    p {
        margin-top: 1em;
    }
    dl {
        margin-bottom: 1em !important;
    }
    width: 100%;
    background: #fff;
    border-top: solid 2px #ccc;
    padding-top: 10px;
}

.detail_content {
    width: 1125px;
    margin: auto;
    height: 660px;
    border: solid 1px #ccc;
    background: #fff;
    .detail_info {
        width: 98%;
        padding: 0 1%;
        margin: auto;
        dl {
            display: flex;
            padding-top: 35px;
            dt {
                height: 220px;
                width: 320px;
                border: solid 1px #ccc;
                text-align: center;
                .storePic {
                    display: block;
                    cursor: pointer;
                    width: 270px;
                    height: 180px;
                    margin: auto;
                    margin-top: 5px;
                }
                p {
                    display: flex;
                    margin-top: 5px;
                    padding-left: 25px;
                    .pic {
                        display: inline-block;
                        margin-right: 5px;
                    }
                }
            }
            dd {
                padding-left: 14px;
                flex: 1;
                display: flex;
                justify-content: space-between;
                .detail_discribe {
                    h4 {
                        font-size: 20px;
                        font-weight: bold;
                    }
                    p {
                        display: flex;
                        align-items: center;
                        span {
                            &:nth-child(1) {
                                margin-right: 10px;
                                width: 60px;
                            }
                        }
                    }
                    .conceal {
                        display: inline-block;
                        width: 300px;
                        /*定义块元素的宽度*/
                        white-space: nowrap;
                        /*内容超宽后禁止换行显示*/
                        overflow: hidden;
                        /*超出部分隐藏*/
                        text-overflow: ellipsis;
                        /*文字超出部分以省略号显示*/
                    }
                }
                .attestationContainer {
                    display: flex;
                    flex-direction: column;
                    .Certification_details {
                        display: flex;
                        .Certification_title {
                            margin-right: 5px;
                        }
                        .Certification_card {
                            .Certification_card_list {
                                margin-bottom: 5px;
                                vertical-align: top;
                                .card_01,
                                .card_02,
                                .card_03 {
                                    color: #0099ff;
                                    cursor: pointer;
                                }
                            }
                            .Certification_card_list span {
                                width: 110px;
                                display: inline-block;
                                img {
                                    display: inline-block;
                                    margin-right: 5px;
                                    position: relative;
                                    top: -1px;
                                }
                            }
                        }
                    }
                    .commitmentContainer {
                        display: flex;
                        flex-direction: column;
                        .commitmentTitle {
                            margin-right: 5px;
                        }
                        .commitmentContent {
                            display: flex;
                            flex-direction: column;
                            span {
                                i {
                                    display: inline-block;
                                    font-style: normal;
                                    &:first-of-type {
                                        margin-left: 10px;
                                    }
                                }
                                img {
                                    width: 22px;
                                    height: auto;
                                    margin-right: 5px;
                                    margin-bottom: 5px;
                                }
                            }
                        }
                        .readMore {
                            text-align: center;
                            color: #0099ff;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .line {
        background: #eee;
        height: 4px;
    }
    .business_consultation {
        width: 96%;
        padding: 2%;
        .business_consultation_title {
            span {
                &:nth-child(1) {
                    color: #ff6b37;
                    font-size: 18px;
                    font-weight: bold;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }
    .business_consultation_relation {
        p {
            .shortened {
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background: #32cc78;
                line-height: 30px;
                text-align: center;
                color: #fff;
            }
            .checkPic {
                cursor: pointer;
            }
            span {
                margin-right: 80px;
                img {
                    display: inline-block;
                    margin-right: 6px;
                    position: relative;
                    top: -1px;
                }
            }
            .peopleName {
                width: 80px;
                display: inline-block;
            }
            .phone {
                width: 150px;
                display: inline-block;
            }
            .QQ {
                width: 150px;
                display: inline-block;
            }
        }
    }
}
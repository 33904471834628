.productListPage {
    .productContainer {
        width: 1500px;
        margin: auto;
        border: solid 1px #ccc;
        padding: 2%;
        margin-top: 15px;
        margin-top: 60px;
        .searchContainer {
            .search {
                display: flex;
                height: 35px;
                line-height: 35px;
                input {
                    width: 400px;
                    border: solid 2px #ff6b37;
                    padding-left: 10px;
                    height: 35px;
                    box-sizing: border-box;
                }
                span {
                    width: 80px;
                    height: 35px;
                    line-height: 35px;
                    background: #ff6b37;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                }
                p {
                    margin-left: 8px;
                }
            }
        }
        .productList {
            margin-top: 15px;
            .producTtable {
                .quantityContainer {
                    display: flex;
                    height: 30px;
                    span {
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        border: solid 1px #ccc;
                        background: rgba(0, 0, 0, .5);
                        color: #fff;
                    }
                    input {
                        width: 60px;
                        height: 25px;
                        margin-left: 8px;
                        margin-right: 8px;
                        border: solid 1px #ccc;
                        border-radius: 3px;
                        text-align: center;
                    }
                }
                .addshopCart {
                    color: #1890ff;
                    cursor: pointer;
                }
            }
        }
        .product_pagination {
            display: flex;
            margin-top: 15px;
            justify-content: center;
            align-items: center;
            position: relative;
            .batchAddCart {
                position: absolute;
                left: 0;
                width: 120px;
                height: 35px;
                line-height: 35px;
                background: #ff6b37;
                color: #fff;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
            }
            .first_button {
                margin-right: 10px;
                cursor: pointer;
            }
            .last_button {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}
.botton{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
}
.color{
    color:#1890ff;
}
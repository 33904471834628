@charset 'utf-8';

[class^='icon-'],
[class*=' icon-'] {
  display: inline-block;

  vertical-align: middle;

  background-repeat: no-repeat;
  background-size: contain;
}

////
/// 项目通用scss
///
/// @group common
/// @author wangxin
////
@charset 'utf-8';
.filter-box,
.table-box {
  margin-top: 20px;

  border: 1px solid #e9e9e9;
}

.filter-box {
  .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 50px;

    padding-left: 20px;

    color: #333;
    border-bottom: 1px solid #e9e9e9;
    background: #f9f9f9;
  }
}

.table-box {
  overflow: hidden;

  padding: 0 20px;
  .title {
    font-size: 13px;
    font-weight: 500;
    line-height: 60px;

    overflow: hidden;

    color: #333;
  }
}

.w {
  min-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.h {
  min-width: 1140px;
}

.first-title {
  line-height: 40px;

  position: relative;

  overflow: hidden;

  padding-left: 20px;

  border-bottom: 1px solid #d6d6d6;
  &::before {
    position: absolute;
    top: 10px;
    left: 10px;

    width: 2px;
    height: 20px;

    content: ' ';

    background: #8bb8e2;
  }
  .back {
    float: right;

    margin-right: 20px;
  }
}
.my-pagination {
  float: right;

  margin: 20px 0;
}

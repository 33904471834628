.storeFooters {
    background: rgba(242, 242, 242, 1);
    height: 150px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #999   ;
   
    }
    .storeFooterImg{
        display: block;
        width: 1200px;
        margin: 0 auto;
    }
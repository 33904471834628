.buyerOrderPage {
    .buyerOrderCotainer {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .buyerOrderCatalog {
            width: 15%;
            border: solid 1px #ccc;
            height: 756px;
            border-radius: 3px;
            .title {
                display: flex;
                justify-content: center;
                border-bottom: solid 1px #ccc;
                span {
                    height: 55px;
                    line-height: 55px;
                }
            }
            p {
                display: flex;
                margin-bottom: 0px;
                span {
                    width: 100%;
                    text-align: center;
                    height: 55px;
                    line-height: 55px;
                    background: #ecf5ff;
                    color: #368DF4;
                    cursor: pointer;
                }
            }
        }
        .buyerOrderContent {
            // flex: 1;
            // height: 756px;
            // display: flex;
            // flex-direction: column;
            // box-sizing: border-box;
          
            .title {
                display: flex;
                border-bottom: solid 1px #e8e8e8;
                height: 40px;
                line-height: 38px;
                padding-left: 0;
                background: white;
                // margin-left: 15px;
                text-align: center;
                span {
                    width: 90px;
                    height: 40px;
                    border: solid 1px #e8e8e8;
                    border-bottom: 1px solid white;
                    background: white;
                    border-radius: 3px;
                    color: #368DF4;
                }
            }
            .buyerOrderSearchContainer {
                
                width: 100%;
                height: 130px;
                border: 1px solid #eee;
                margin-top: 16px;
                .ant-select{
                    width: 100px !important;
                }
                .btn{
                    position: absolute;
                    right: 10px;
                    margin-left: 10px;
                }
                .myinput{
                    height: 30px;
                    border: 0;
                    outline: none;
                    border: solid 1px #ccc;
                    margin-top: 8px;
                    padding-left: 8px;
                    border-radius: 4px;
                }
               
                .ant-select-selection--single {
                    height: 30px ;
                    border: solid 1px #ccc;
                    border-radius: 4px;
                    
                }
               
                .buyerOrderSearch {
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        width: 70px;
                        text-align: right;
                        display: inline-block;
                        margin-right: 8px;
                    }
                    input {
                        width: 250px;
                        height: 30px;
                        border: 0;
                        outline: none;
                        border: solid 1px #ccc;
                        margin-top: 8px;
                        padding-left: 8px;
                    }
                    .BusinessQueryData {
                        text-align: left;
                        margin-top: 8px;
                        .separator {
                            font-weight: normal;
                            display: inline-block;
                            margin-right: 5px;
                        }
                        input {
                            margin-top: 0;
                            width: auto;
                            padding-left: 12;
                            border-radius: 0;
                        }
                    }
                }
              
            .buyerOrderList {
                margin-top: 15px;
                p {
                    border: solid 1px #ccc;
                    margin-bottom: 0px;
                    height: 50px;
                    line-height: 50px;
                    padding-left: 20px;
                }
            }
        }
    }
    .list_info_pagination {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        line-height: 32px;
        padding-bottom: 30px;
        padding-top: 10px;
        .first_button {
            margin-right: 10px;
            display: inline-block;
            cursor: pointer;
            font-size: 15px;
        }
        .last_button {
            margin-left: 10px;
            display: inline-block;
            cursor: pointer;
            font-size: 15px;
        }
        .ban {
            cursor: not-allowed;
        }
    }
    .link{
        color: #1890ff;
    }
    .myinput{
        height: 30px;
        border: 0;
        outline: none;
        border: solid 1px #ccc;
        margin-top: 8px;
        padding-left: 8px;
        border-radius: 4px;
    }
    
}
.buyerOrderContent{
    position: relative;
    height: 255px !important;
    margin-left: 10px;
    .row-p{
        width: 100%;
        
        margin-left: 20px;
        margin-top: 20px;
        position: relative;
        .col{
            width: 300px;
            height: 30px;
            
            .col-span{
                font-size: 12px;
                width: 100px;
            }
            .col-input{
                width: 150px;
                height: 31px;
                margin-left: 9px;
                border-radius: 5px;
                border: 1px solid rgba(104, 100, 100, 0.199);
               
            }
            .col-input:hover{
                border: 1px solid rgb(88, 157, 248);
            }
        }
        .col-1{
           
            width: 48%;
            height: 40px;
           
            .col-span{
                font-size: 12px;
                width: 100px;
            }
        }
        .col-2{
            position: absolute;
            top: 6px;
            right: 52px;
           
        }
    }
    .searchButton{
        position: absolute;
        bottom: 75px;
        right: 10px;
    }
}
}
.bu-p{
    display: block;
    padding-top: 10px;
    line-height: 20px;
    margin-bottom: 14px;
}
.Broadsides{
    width: 180px;
    border: solid 1px #ccc;
    height: 756px;
    border-radius: 3px;
    margin-top: 0 !important;
    .title {
        width: 180px;
        text-align: center;
        color: #111;
        span {
            height: 55px;
            line-height: 55px;
        }
    }
    p{
        display: flex;
        margin-bottom: 0px;
        border-bottom: 1px solid #eee;
        display: block;
        text-align: center;
        height: 44px;
        line-height: 44px;
        cursor: pointer;
    }
    .title-p{
        display: flex;
        margin-bottom: 0px;
        border: 1px solid #eee;
        display: block;
        text-align: center;
        height: 44px;
        line-height: 44px;
        background: #ECF5FF;
        color:#368DF4;
       
    }
}
.buyerOrderListTable{
    margin-bottom: 14px;
}
.ant-calendar-picker{
    width: 145px;
}
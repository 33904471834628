.registerPage {
    height: 100vh;
    background: url('./images/bg.jpg');
    background-size: cover;
    display: flex;
    overflow: auto;
    .registerContainer {
        margin: auto;
        width: 400px;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    }
    .RegistrationType {
        width: 100%;
        height: 44px;
        line-height: 44px;
        background: #409eff;
        color: #fff;
        text-align: center;
        span {
            width: 50%;
            color: #409eff;
            text-align: center;
            cursor: pointer;
            margin-right: 25px;
        }
        .active1 {
            background: #409eff;
            color: #fff;
        }
    }
    .registerContentPersonage,
    .registerContentCompany {
        .ant-select,
        .ant-select-selection {
            border-radius: 4px;
        }
        padding-top: 30px;
        .ant-form-item {
            margin-top: -8px;
        }
        .registerArea {
            text-align: center;
        }
        .registerButton {
            width: 120px;
            display: inline-block;
        }
        .avatar-uploader>.ant-upload {
            width: 80px;
            height: 80px;
        }
        .ownNumber {
            text-align: right;
            color: #409eff;
            padding-right: 45px;
            margin-top: -20px;
            cursor: pointer;
        }
    }
    .registerContentPersonage {
        height: 340px;
    }
    .registerContentCompany {
        height: 560px;
    }
    .fanhui{
        float: left;
        line-height: 44px;
        margin-left: 10px;
        cursor: pointer;
        font-size: 20px;
    }
}